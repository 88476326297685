import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Sidebar from "../../components/sidebar/sidebar.jsx";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios"; // Ensure axios is imported

function EmbeddedDoc() {
  const [documents, setDocuments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const token = sessionStorage.getItem('token');
  const docType = "embedded";
  const limit = 5; // Set limit to 5 documents per page

  useEffect(() => {
    fetchDocuments(currentPage);
  }, [currentPage]); // Fetch documents when the page changes

  const fetchDocuments = async (cp) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/getDocList?page=${cp}&limit=${limit}&type=${docType}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const { documents, currentPage, totalPages } = response.data;
      setDocuments(documents);
      setCurrentPage(currentPage);
      setTotalPages(totalPages);
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  // Function to open the iframe in a new tab
  const openInNewTab = (iframeUrl) => {
    window.open(iframeUrl, '_blank'); // Opens the iframe URL in a new tab
  };

  return (
    <div className="d-flex">
      <Sidebar />
      <div className="content p-4" style={{ flex: 1 }}>
        <h1>Embedded Documents</h1>
        <div className="iframe-container d-flex flex-wrap justify-content-start">
          {/* Map over fetched documents */}
          {documents.map((doc, index) => (
            <div key={index} className="iframe-box card-custom m-2" style={{ width: '300px', textAlign: 'center', border: '1px solid #ddd', borderRadius: '4px', overflow: 'hidden', backgroundColor: '#f8f9fa', padding: '10px' }}>
              <h4 style={{ marginBottom: "25px", fontSize: '16px' }}>{doc.title}</h4> {/* Title from backend */}
              <iframe
                id={`iframe-${index}`} // Give each iframe a unique ID
                width="100%"
                height="300"
                src={doc.docs[0]} // Use first URL from docs array
                frameBorder="0"
                style={{ border: '0' }}
                allow="fullscreen"
                allowfullscreen
                sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
              ></iframe>
              <p style={{ marginTop: "25px" }}>{doc.description}</p> {/* Description from backend */}
              
              {/* Fullscreen button opens iframe URL in new tab */}
              <button 
                className="btn btn-primary mt-2"
                onClick={() => openInNewTab(doc.docs[0])} // Opens the iframe URL in a new tab on click
              >
               Full Screen
              </button>
            </div>
          ))}
        </div>

        {/* Pagination controls */}
        <div className="pagination d-flex justify-content-center mt-4">
          <button 
            className="btn btn-secondary mx-1" 
            onClick={() => handlePageChange(currentPage - 1)} 
            disabled={currentPage === 1}>
            Previous
          </button>
          <span className="mx-2" style={{ fontSize: '18px' }}>Page {currentPage} of {totalPages}</span>
          <button 
            className="btn btn-secondary mx-1" 
            onClick={() => handlePageChange(currentPage + 1)} 
            disabled={currentPage === totalPages}>
            Next
          </button>
        </div>

        <ToastContainer />
      </div>
    </div>
  );
}

export default EmbeddedDoc;
