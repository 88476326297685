// reducers/index.js
import { combineReducers } from 'redux';
import themeSlice from './slices/themeSlice'; // Import your slice reducer
import authSlice from './slices/authSlice';
import signUpSlice from './slices/signupSlice';
import getUser from './slices/getUser';
import setSectionNameSlice from './slices/setSectionNames';

// Combine your reducers here
const rootReducer = combineReducers({
  theme: themeSlice,
  auth: authSlice,
  signUp:signUpSlice,
  getUser:getUser,
  sectionName:setSectionNameSlice
});

export default rootReducer;