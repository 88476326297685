import React, { useState, useEffect } from "react";
import axios from "axios";
import { Card } from "./../../components/card/card.jsx";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import Sidebar from "../../components/sidebar/sidebar.jsx";
import { FaFileAlt } from "react-icons/fa"; // Icon for documents
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';
import { Title } from "chart.js";


function CustomizedPermission() {
  const [addShow, setAddShow] = useState(false);
  const handleAddClose = () => setAddShow(false);
  const handleAddShow = () => {
    setErrorMessages({})
setPositionId("")
setPermissionName("")
setSelectedParent("")
setPosition("")

    setAddShow(true)

};

  const [editShow, setEditShow] = useState(false);
  const handleEditClose = () =>{ 
    setEditShow(false)
    setCurrentDocument({});
    setSubSectionThere(false)

  }

  const [deleteShow, setDeleteShow] = useState(false);
  const handleDeleteClose = () => setDeleteShow(false);
  const handleDeleteShow = (perm) => {
    setDeletePermission(perm);
    setSelectedFiles([]); 
    setDeleteShow(true);
  };

  const [docsShow, setDocsShow] = useState(false);
  const handleDocsClose = () => setDocsShow(false);


  

  // State for permissions
  const [permissions, setPermissions] = useState([]);
  const [parentPermissions, setParentPermissions] = useState([]);
  const [subPermissions, setSubPermissions] = useState([]);
  const [removeDocs, setRemoveDocs] = useState([]); // To keep track of removed documents
  const [positionId,setPositionId]=useState("")
  const [position,setPosition]=useState("")

  // Selected parent and sub-section
  const [selectedParent, setSelectedParent] = useState("");
  const [selectedSub, setSelectedSub] = useState("");
  const [errorMessages, setErrorMessages] = useState({}); // State for error messages

  // State to track selected files
  const [selectedFiles, setSelectedFiles] = useState([]);
  
  // State to track tags as string
  const [tags, setTags] = useState("");

  // State to track title and type
  const [title, setTitle] = useState("");
  const [permissionName, setPermissionName] = useState("");
  const [routeName, setRouteName] = useState("");
  const [sequenceNumber, setSequenceNumber] = useState("");
  const [link, setLink] = useState("");
  const [description, setDescription] = useState("");
  const [docType, setDocType] = useState("embedded");
  const [selectedSectionType, setSelectedSectionType] = useState("mainSection");
  const [openedEditSection, setOpenedEditSection] = useState("mainSection");

  const [subSectionThere, setSubSectionThere] = useState(false);

  // State to track current document for editing
  const [currentDocument, setCurrentDocument] = useState(null);
  const [deletePermission, setDeletePermission] = useState(null);

  // State to track fetched documents and pagination
  const [documents, setDocuments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const token = sessionStorage.getItem('token');

  // Fetch permissions and document list on component mount
  useEffect(() => {
    fetchPermissions();
    // fetchDocuments(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if(position && selectedParent){
    getUpdatedSequence()
    }
  }, [position,selectedParent]);

  useEffect(() => {
    if(position ){
    getUpdatedSequenceSub()
    }
  }, [position,selectedSub]);

  const getUpdatedSequence = ( ) => {
    if(selectedSectionType=="mainSection"){
       const subPermissions = permissions.filter(perm => perm.parentId === parseInt(selectedParent));
    //    console.log("sub==>>",subPermissions[subPermissions.length - 1].sequenceNumber+1)
       
       const parentIndex = parentPermissions.findIndex(perm => perm.id === parseInt(selectedParent));
       console.log("parentindex",parentIndex)
       
       if (position === "After" && parentIndex < parentPermissions.length - 1) {
           setPositionId(parentPermissions[parentIndex + 1].sequenceNumber)
    
       }
        if (position === "After" && parentIndex == parentPermissions.length - 1) {
           if(subPermissions.length){
               console.log("hello2")
               setPositionId(subPermissions[subPermissions.length - 1].sequenceNumber+1)
           }else{
           setPositionId(parentPermissions[parentPermissions.length - 1].sequenceNumber+1)
           }
       }
       if (position === "Before" && parentIndex > 0) {
           setPositionId(parentPermissions[parentIndex ].sequenceNumber)
   
       }
        if (position === "Before" && parentIndex == 0) {
           setPositionId(0)
   
       }
   }
   
     };
   
     const getUpdatedSequenceSub = ( ) => {
   
   if(selectedSectionType=="subSection"){
      
       if(!selectedSub){
          
           const parentIndex = parentPermissions.findIndex(perm => perm.id === parseInt(selectedParent));
           console.log("parentIndex",parentIndex[parentIndex])
           setPositionId(parentPermissions[parentIndex]?.sequenceNumber+1)
       }else{
           const subIndex = subPermissions.findIndex(perm => perm.id === parseInt(selectedSub));
           console.log("subIndex",subIndex)
           
           if (position === "After" && subIndex < subPermissions?.length - 1) {
               setPositionId(subPermissions[subIndex + 1]?.sequenceNumber)
        
           }
            if (position === "After" && subIndex == subPermissions?.length - 1) {
               setPositionId(subPermissions[subPermissions?.length - 1]?.sequenceNumber+1)
         
           }
           if (position === "Before" ) {
              
               setPositionId(subPermissions[subIndex ]?.sequenceNumber)
       
           }
       }
       
      
   }
   
     };


  


  useEffect(() => {
    if(currentDocument?.position && currentDocument?.mainSection){
        getUpdatedSequenceEdit()
    }
  }, [currentDocument?.position,currentDocument?.mainSection]);

  useEffect(() => {
    if(currentDocument?.position ){
        getUpdatedSequenceSubEdit()
    }
  }, [currentDocument?.position,currentDocument?.subSectionId]);


  const getUpdatedSequenceEdit = ( ) => {
    if(openedEditSection=="mainSection"){
       const subPermissions = permissions.filter(perm => perm.parentId === parseInt(currentDocument.mainSection));
       console.log("sub==>>",currentDocument.mainSection)
       
       const parentIndex = parentPermissions.findIndex(perm => perm.id === parseInt(currentDocument.mainSection));
       console.log("parentindex",parentIndex)
       
       if (currentDocument.position === "After" && parentIndex < parentPermissions.length - 1) {
        console.log("seq==>>no",parentPermissions[parentIndex + 1])
           setPositionId(parentPermissions[parentIndex + 1].sequenceNumber)
    
       }
        if (currentDocument.position === "After" && parentIndex == parentPermissions.length - 1) {
           if(subPermissions.length){
               console.log("hello2")
               setPositionId(subPermissions[subPermissions.length - 1].sequenceNumber+1)
           }else{
           setPositionId(parentPermissions[parentPermissions.length - 1].sequenceNumber+1)
           }
       }
       if (currentDocument.position === "Before" && parentIndex > 0) {
           setPositionId(parentPermissions[parentIndex ].sequenceNumber)
   
       }
        if (currentDocument.position === "Before" && parentIndex == 0) {
           setPositionId(0)
   
       }
   }
   
     };
   
     const getUpdatedSequenceSubEdit = ( ) => {
   
   if(openedEditSection=="subSection"){
      
       if(!currentDocument.subSectionId){
          
           const parentIndex = parentPermissions.findIndex(perm => perm.id === parseInt(currentDocument.parentId));
           console.log("parentIndex",parentIndex[parentIndex])
           setPositionId(parentPermissions[parentIndex]?.sequenceNumber+1)
       }else{
           const subIndex = subPermissions.findIndex(perm => perm.id === parseInt(currentDocument.subSectionId));
           console.log("subIndex",subIndex)
           
           if (currentDocument.position === "After" && subIndex < subPermissions?.length - 1) {
               setPositionId(subPermissions[subIndex + 1]?.sequenceNumber)
        
           }
            if (currentDocument.position === "After" && subIndex == subPermissions?.length - 1) {
               setPositionId(subPermissions[subPermissions?.length - 1]?.sequenceNumber+1)
         
           }
           if (currentDocument.position === "Before" ) {
              
               setPositionId(subPermissions[subIndex ]?.sequenceNumber)
       
           }
       }
       
      
   }
   
     };

  // Pre-populate subcategories when editing
  useEffect(() => {
    if (editShow && currentDocument?.parentId) {
      const subPermissions = permissions.filter(perm => perm.parentId === parseInt(currentDocument.parentId));
      setSubPermissions(subPermissions);
    }
  }, [editShow, currentDocument?.parentId, permissions]);

  // Fetch all permissions using Axios
  const fetchPermissions = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/getAllPermissions`);
      const allPermissions = response.data;

      const parentPermissions = allPermissions.filter(perm => perm.parentId === null);
      setPermissions(allPermissions);
      setParentPermissions(parentPermissions);
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  const handleSectionTypeChange = (e) => {
    const sectionType = e.target.value;
    setSelectedSectionType(sectionType);
 
    setErrorMessages({}); // Clear errors
  };

  // Fetch documents using Axios with pagination
  const fetchDocuments = async (page) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/getTitleList?page=${page}&limit=5&type=${docType}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { documents, currentPage, totalPages } = response.data;

      setDocuments(documents);
      setCurrentPage(currentPage);
      setTotalPages(totalPages);
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  // Handle parent category change (works for both Add and Edit)
  const handleParentChange = (e) => {
    const selectedParentId = e.target.value;
    
    if (editShow) {
      setCurrentDocument((prevDocument) => ({
        ...prevDocument,
        parentId: selectedParentId,
        subSectionId: "", // Reset subcategory when parent changes
      }));
    } else {
      setSelectedParent(selectedParentId);
      setSelectedSub("");
    }

    const subPermissions = permissions.filter(perm => perm.parentId === parseInt(selectedParentId));
    setSubPermissions(subPermissions);
  };

 

  // Handle sub-section change (works for both Add and Edit)
  const handleSubChange = (e) => {
    const selectedSubId = e.target.value;
    if (editShow) {
      setCurrentDocument((prevDocument) => ({
        ...prevDocument,
        subCategoryId: selectedSubId,
      }));
    } else {
      setSelectedSub(selectedSubId);
    }
  };

  // Handle file selection
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files); 
    const newFiles = [...selectedFiles, ...files];
    if (newFiles.length > 10) {
      alert("You can select a maximum of 10 files.");
      return;
    }
    setSelectedFiles(newFiles); 
  };

  // Remove a selected file
  const handleRemoveFile = (index) => {
    const updatedFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(updatedFiles);
  };

  // Handle tags input
  const handleTagsChange = (e) => {
    setTags(e.target.value); 
  };

  const extractTags = (tagsString) => {
    return tagsString.split("#").map(tag => tag.trim()).filter(tag => tag);
  };

  const validateForm = (type) => {
  
    const errors = {};
    if(type=="add"){
        if(selectedSectionType=="mainSection"){
    if (!permissionName) errors.permissionName = 'Permission Name is required';
    if (!selectedParent) errors.selectedParent = 'Main Section is required';
      if (!position) errors.position = 'Position is required';
        }

        if(selectedSectionType=="subSection"){
            if (!permissionName) errors.permissionName = 'Permission Name is required';
            if (!selectedParent) errors.selectedParent = 'Main Section is required';
              if (!position) errors.position = 'Position is required';
             if (!routeName) errors.title = 'Route Name is required';

        }
   
    }
    if(type=="edit"){

        if(openedEditSection=="mainSection"){
            if (!currentDocument?.name) errors.name = 'Permission Name is required';
             if (!currentDocument?.mainSection && currentDocument?.position ) {
                errors.mainSection = 'Main Section is required'
                errors.position = 'Position is required'
             }
             if (currentDocument?.mainSection && !currentDocument?.position ) {
                errors.mainSection = 'Main Section is required'
                errors.position = 'Position is required'
             }
            //   if (!currentDocument?.position) errors.position = 'Position is required';
                }
        
                if(openedEditSection=="subSection"){
                    if (!currentDocument?.name) errors.name = 'Permission Name is required';
                    if (!currentDocument?.parentId) errors.selectedParent = 'Main Section is required';
                      if (!currentDocument?.position && currentDocument?.subSectionId  ){ 
                        errors.position = 'Position is required';
                        errors.subSectionId = 'SubSection is required';

                      }
                      if (currentDocument?.position && !currentDocument?.subSectionId){ 
                        errors.position = 'Position is required';
                        errors.subSectionId = 'SubSection is required';

                      }

                      if (!currentDocument?.routeName) errors.routeName = 'Route Name is required';
        
                }
    
    }
  
    setErrorMessages(errors);
  
    return Object.keys(errors).length === 0; // return true if no errors
  };

  // Handle form submission for adding the document
  const handleAddDocument = async () => {

     if (!validateForm("add")) return;
   

    if(selectedSectionType=="mainSection"){
        var formData = {
            name:permissionName,
            sectionType:selectedSectionType,
            positionId
        }
    }else{

        var formData = {
            name:permissionName,
            sectionType:selectedSectionType,
            positionId,
            routeName,
            parentId:selectedParent
        }

    }

   
  

    try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/createPermission`, formData
      );
      if (response.status === 201) {
        toast.success('Permission added successfully');
        // handleAddClose();
        setSelectedFiles([]);
        setAddShow(false);
        setRouteName("")
        setPermissionName("")
        setPosition("")
        // setSelectedSectionType("")
        setSelectedParent("")
        setSelectedSub("")
        setSelectedParent("")
        
     


         fetchPermissions()
      }
     
    } catch (error) {
      console.error("Error adding Permission", error);
      toast.error(error.response?.data?.message || 'Error adding Permission');
    }
  };

  // Handle form submission for editing the document
  const handleEditDocument = async () => {

   
    if (!validateForm("edit")) return;



    if(openedEditSection=="mainSection"){
        var formData = {
            name:currentDocument?.name,
          
            positionId
        }
    }else{

        var formData = {
            name:currentDocument?.name,
       
            positionId,
            routeName:currentDocument?.routeName,
            parentId:currentDocument?.parentId
        }

    }

    console.log("form==MMM",formData)
    
    try {
        const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/editPermission/${currentDocument.id}`, formData
    //       ,  {
    //     headers: {
    //       Authorization: `Bearer ${token}`, 
    //     },
    //   }
    );
      if (response.status === 200) {
        toast.success('Permission updated successfully');
        setEditShow(false);
        fetchPermissions()
     setCurrentDocument({})
     setErrorMessages({})
      }
    
    } catch (error) {
      console.error("Error updating Permission :", error);
      toast.error(error.response?.data?.message || 'Error updating Permission ');
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleDeleteDocument = async (id) => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/removePermission/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        toast.success('Permission deleted successfully');
        handleDeleteClose();
        setEditShow(false);
        fetchPermissions()
      
      }
    } catch (error) {
      toast.error(error.response?.data?.message || 'Error deleting Permission');
    }
  };

  // Handle opening the edit modal with the selected document's details
  const handleEditShow = (perm) => {

if(!perm.parentId){
  

    const subPermissionsList = permissions.filter(permi => permi.parentId === parseInt(perm?.id));
    console.log("subperm=>>",perm.id)
   if(subPermissionsList.length){
    setSubSectionThere(true)
   }
setOpenedEditSection("mainSection")

}else{
    setOpenedEditSection("subSection")
}
    setCurrentDocument(perm);
    setCurrentDocument((prevDocument) => ({
        ...prevDocument,
        mainSection:"" ,
        subSectionId: "", // Reset subcategory when parent changes
      }));
    setSelectedFiles([]); 
    setErrorMessages({})
    setPositionId("")
    setEditShow(true);
  };

  const handleDocsShow = (document) =>{
    setCurrentDocument(document);
    setSelectedFiles([]); 

    setDocsShow(true);
  } 

  const handleRemoveExistingDoc = (index) => {
    const removedDoc = currentDocument.docs[index];
    setRemoveDocs([...removeDocs, removedDoc]);
    setCurrentDocument({
      ...currentDocument,
      docs: currentDocument.docs.filter((_, i) => i !== index),
    });
  };

  return (
    <div>
      <Sidebar />
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/">PAGES</a>
            </li>
            <li className="breadcrumb-item active">Customized Permission</li>
          </ul>
          <h1 className="page-header mb-0">Customized Permission</h1>
        </div>

      

        <div className="ms-auto">
          <button className="btn btn-outline-theme" onClick={handleAddShow}>
            <i className="fa fa-plus-circle fa-fw me-1"></i> Add Permission
          </button>
        </div>
      </div>

      <Card>
        <div className="tab-content p-4">
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="border-top-0 pt-0 pb-2">Title</th>
                  <th className="border-top-0 pt-0 pb-2">Parent Section</th>
                  <th className="border-top-0 pt-0 pb-2">Route Name</th>
                 
                
                  <th className="border-top-0 pt-0 pb-2">Action</th>
                </tr>
              </thead>
              <tbody>
                {permissions?.length>0?( permissions?.map((perm) => (
                  <tr key={perm.id}>
                    <td className="align-middle">{perm?.name}</td>
                    <td className="align-middle">{perm?.parentPermission?.name||"N/A"}</td>
                    <td className="align-middle">{perm?.routeName||"N/A"}</td>
                    
                 
                  
                     
                    <td className="align-middle">
                    <div className="action-btn-group d-flex">
                    {/* <button
                          className="btn btn-show border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center mx-2"
                          onClick={() => handleDocsShow(doc)}
                        >
                          Show
                        </button> */}
                      <button className="btn btn-edit border border-warning text-warning px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center" onClick={() => handleEditShow(perm)}>Edit</button>
                      <button className="btn btn-delete border border-danger text-danger px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center" onClick={() => handleDeleteShow(perm)}>Delete</button>
                    </div>
                    </td>
                  </tr>
                ))):(<tr>
                  <td colSpan="6" className="text-center">
                    No Data Found.
                  </td>
                </tr>)}
               
              </tbody>
            </table>
          </div>

          {documents?.length>0?(  <div className="pagination-controls d-flex justify-content-center mt-4">
            <button
              className="btn btn-outline-secondary"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="mx-3">
              Page {currentPage} of {totalPages}
            </span>
            <button
              className="btn btn-outline-secondary"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>):(null)}
        
        </div>
      </Card>

      {/* Add Document Modal */}
      <Modal show={addShow} onHide={handleAddClose} className="right-side-modal" scrollable={true}>
  <Modal.Header closeButton>
    <Modal.Title>Add Permission</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <div className="add-document-form">
      <div className="form-group">
        <label>Name</label>
        <input
          type="text"
          placeholder="Add Permission Name"
          value={permissionName}
          onChange={(e) => setPermissionName(e.target.value)}
          className={`form-control ${errorMessages.permissionName ? 'is-invalid' : ''}`}
        />
        {errorMessages?.permissionName && <div className="invalid-feedback">{errorMessages?.permissionName}</div>}
      </div>

      <div className="add-document-form">
          <div className="form-group">
            <label>Section Type</label>
            <select
              className={`form-select ${errorMessages.newDropdown ? 'is-invalid' : ''}`}
              value={selectedSectionType}
              onChange={handleSectionTypeChange}
            
            >
              <option value="">Select Option</option>
              <option value="mainSection">
                 Main Section
                </option>
                <option value="subSection">
                 Sub Section
                </option>
            </select>
            {/* {errorMessages.newDropdown && (
              <div className="invalid-feedback">{errorMessages.newDropdown}</div>
            )} */}
          </div>
        </div>

      

      
        {selectedSectionType === "subSection" && 
         <>
        <div className="form-group">
        <label>Main Section</label>
        <select
          className={`form-select ${errorMessages.selectedParent ? 'is-invalid' : ''}`}
          value={selectedParent}
          onChange={handleParentChange}
        >
          <option value="">Select Parent Section</option>
          {parentPermissions.map((perm) => (
            <option key={perm.id} value={perm.id}>
              {perm.name}
            </option>
          ))}
        </select>
        {errorMessages.selectedParent && <div className="invalid-feedback">{errorMessages.selectedParent}</div>}
      </div>
        <div className="form-group">
        <label>Route Name</label>
        <input
          type="text"
          placeholder="Add Route Name"
          value={routeName}
          onChange={(e) => setRouteName(e.target.value)}
          className={`form-control ${errorMessages.title ? 'is-invalid' : ''}`}
        />
        {errorMessages?.title && <div className="invalid-feedback">{errorMessages?.title}</div>}
      </div>
     </>
      }
     

    

     

      {/* Row for the new dropdown and Sub Section dropdown */}
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label>Position</label>
            <select
              className={`form-select ${errorMessages.position ? 'is-invalid' : ''}`}
              disabled={!selectedParent}
              onChange={(e)=>{
               
                setPosition(e.target.value)
            }
            }
            >
              <option value="">Select Option</option>
              <option value="After">
                 After
                </option>
                <option value="Before">
                 Before
                </option>
            </select>
            {errorMessages.position && (
              <div className="invalid-feedback">{errorMessages.position}</div>
            )}
          </div>
        </div>

        <div className="col-md-6">
            {selectedSectionType === "subSection"?
             <div className="form-group">
             <label>Sub Section</label>
             <select
               className={`form-select ${errorMessages.subsection ? 'is-invalid' : ''}`}
               value={selectedSub}
               onChange={handleSubChange}
               disabled={!selectedParent}
             >
               <option value="">Select Sub Section</option>
               {subPermissions.map((subPerm) => (
                 <option key={subPerm.id} value={subPerm.id}>
                   {subPerm.name}
                 </option>
               ))}
             </select>
             {errorMessages.subsection && (
               <div className="invalid-feedback">{errorMessages.subsection}</div>
             )}
           </div>
            :
            <div className="form-group">
            <label>Main Section</label>
            <select
              className={`form-select ${errorMessages.selectedParent ? 'is-invalid' : ''}`}
              value={selectedParent}
              onChange={handleParentChange}
            >
              <option value="">Select Parent Section</option>
              {parentPermissions.map((perm) => (
                <option key={perm.id} value={perm.id}>
                  {perm.name}
                </option>
              ))}
            </select>
            {errorMessages.selectedParent && <div className="invalid-feedback">{errorMessages.selectedParent}</div>}
          </div>
            }
         
        </div>
      </div>

      <div className="form-button mt-3">
        <button className="btn btn-outline-theme" onClick={handleAddDocument}>
          + ADD
        </button>
      </div>
    </div>
  </Modal.Body>
</Modal>


      {/* Edit Document Modal */}
      <Modal show={editShow} onHide={handleEditClose} className="right-side-modal" scrollable={true}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Permission</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="add-document-form">
            <div className="form-group">
              <label>Permission Name</label>
              <input
                type="text"
                placeholder="Document Title"
                value={currentDocument?.name || ""}
                onChange={(e) => setCurrentDocument({...currentDocument, name: e.target.value})}
                className={`form-control ${errorMessages.name ? 'is-invalid' : ''}`}
              />
           {errorMessages?.name && <div className="invalid-feedback" >{errorMessages?.name}</div>}

            </div>



          
            
        {openedEditSection === "subSection" && 
         <>
        <div className="form-group">
        <label>Main Section</label>
        <select
          className={`form-select ${errorMessages.selectedParent ? 'is-invalid' : ''}`}
          value={currentDocument?.parentId || ""}
          onChange={handleParentChange}
        >
          <option value="">Select Parent Section</option>
          {parentPermissions.map((perm) => (
            <option key={perm.id} value={perm.id}>
              {perm.name}
            </option>
          ))}
        </select>
        {errorMessages.selectedParent && <div className="invalid-feedback">{errorMessages.selectedParent}</div>}
      </div>
        <div className="form-group">
        <label>Route Name</label>
        <input
          type="text"
          placeholder="Add Route Name"
          value={currentDocument?.routeName || ""}
          onChange={(e) => setCurrentDocument({...currentDocument, routeName: e.target.value})}
          className={`form-control ${errorMessages.routeName ? 'is-invalid' : ''}`}
        />
        {errorMessages?.routeName && <div className="invalid-feedback">{errorMessages?.routeName}</div>}
      </div>
     </>
      }
     

    

     

      {/* Row for the new dropdown and Sub Section dropdown */}
   
      <div className="row">
        <div className="col-md-6">
        {openedEditSection === "mainSection" && subSectionThere?null:<div className="form-group">
            <label>Position</label>
            <select
              className={`form-select ${errorMessages.position ? 'is-invalid' : ''}`}
            //   disabled={!selectedParent}
              onChange={
                (e) => setCurrentDocument({...currentDocument, position: e.target.value})
            }
            >
              <option value="">Select Option</option>
              <option value="After">
                 After
                </option>
                <option value="Before">
                 Before
                </option>
            </select>
            {errorMessages.position && (
              <div className="invalid-feedback">{errorMessages.position}</div>
            )}
          </div>}   
        </div>

        <div className="col-md-6">
            {openedEditSection === "subSection"?
             <div className="form-group">
             <label>Sub Section</label>
             <select
               className={`form-select ${errorMessages.subsectionId ? 'is-invalid' : ''}`}
               value={currentDocument?.subSectionId || ""}
               onChange={ (e) => setCurrentDocument({...currentDocument, subSectionId: e.target.value})}
               disabled={!currentDocument.parentId}
             >
               <option value="">Select Sub Section</option>
               {subPermissions.map((subPerm) => (
                 <option key={subPerm.id} value={subPerm.id}>
                   {subPerm.name}
                 </option>
               ))}
             </select>
             {errorMessages.subsectionId && (
               <div className="invalid-feedback">{errorMessages.subsectionId}</div>
             )}
           </div>
            :(!subSectionThere? <div className="form-group">
                <label>Main Section</label>
                <select
                  className={`form-select ${errorMessages.mainSection ? 'is-invalid' : ''}`}
                  value={currentDocument?.mainSection || ""}
                  onChange={(e) => setCurrentDocument({...currentDocument, mainSection: e.target.value})}
                >
                  <option value="">Select Parent Section</option>
                  {parentPermissions.map((perm) => (
                    <option key={perm.id} value={perm.id}>
                      {perm.name}
                    </option>
                  ))}
                </select>
                {errorMessages.mainSection && <div className="invalid-feedback">{errorMessages.mainSection}</div>}
              </div>:null)
           
            }
         
        </div>
      </div>

          

         

        

             

           

            <div className="form-button mt-3">
              <button className="btn btn-outline-theme" onClick={handleEditDocument}> Save Changes</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

       {/* Delete Confirmation Modal */}
       <Modal show={deleteShow} onHide={handleDeleteClose} className="delete-modal">
        <Modal.Header closeButton>
          <Modal.Title>Delete  Permission</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this Pemission?</p>
          <div className="delete-btn-group">
            <button className="btn dlt-btn dlt-btn-close" onClick={handleDeleteClose}>
              Cancel
            </button>
            <button className="btn dlt-btn" onClick={() => handleDeleteDocument(deletePermission.id)}>Delete</button>
          </div>
        </Modal.Body>
      </Modal>

  {/* Document List Modal */}
{/* <Modal show={docsShow} onHide={handleDocsClose} className="right-side-modal">
  <Modal.Header closeButton>
    <Modal.Title> Embedded Data Links</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <div className="docs-list-area">
     
  
                    {currentDocument?.docs[0]} 
               
       
    </div>
  </Modal.Body>
</Modal> */}

<ToastContainer />


    </div>
  );
}

export default CustomizedPermission;
