import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { userRoles } from '../../../src/assets/userRoles';


function ChangePasswordProfile() {

  const userInfo = useSelector((state) => state.getUser.user); // Fetch userInfo from Redux
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Error states
  const [newPasswordError, setNewPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');

  // Function to validate password strength
  const isValidPassword = (password) => {
    // At least 8 characters, one uppercase letter, one number, and one special character
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/;

    return passwordRegex.test(password);
  };

  // Function to validate fields
  const validateFields = () => {
    let isValid = true;
    setNewPasswordError('');
    setConfirmPasswordError('');

    if (!newPassword) {
      setNewPasswordError('New Password is required.');
      isValid = false;
    } else if (!isValidPassword(newPassword)) {
      setNewPasswordError(
        'Password must be at least 8 characters long and include at least one uppercase letter, one number, and one special character.'
      );
      isValid = false;
    }

    if (!confirmNewPassword) {
      setConfirmPasswordError('Confirm Password is required.');
      isValid = false;
    } else if (newPassword !== confirmNewPassword) {
      setConfirmPasswordError('Passwords do not match.');
      isValid = false;
    }

    return isValid;
  };

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateFields()) {
      return;
    }

    try {
      const payload = {
        newPassword: newPassword,
      };
if(userInfo?.Role?.name== userRoles?.client){
      var response = await axios.put(`${process.env.REACT_APP_BASE_URL}/changePassword/${userInfo.id}`, payload, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`, // Assumes token is stored in sessionStorage
        },
      });
    }else{
      var response = await axios.put(`${process.env.REACT_APP_BASE_URL}/changePasswordAdmin/${userInfo.id}`, payload, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`, // Assumes token is stored in sessionStorage
        },
      });
    }


      if (response.status === 200) {
        toast.success('Password changed successfully');
        setNewPassword('');
        setConfirmNewPassword('');
      }
    

    
    } catch (error) {
      toast.error('An error occurred while changing the password');
    }
  };

  return (
    <div className="change-password">
      <div className="change-password-content">
        <ToastContainer />
        <form onSubmit={handleSubmit}>
          <h1 className="text-center">Change Password</h1>

          {/* New Password Field */}
          <div className="mb-3">
            <label className="form-label">New Password</label>
            <div className="input-group">
              <input
                type={showNewPassword ? 'text' : 'password'}
                className="form-control form-control-lg bg-white bg-opacity-5"
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                  setNewPasswordError('');
                }}
              />
              <span className="input-group-text" onClick={() => setShowNewPassword(!showNewPassword)}>
                <FontAwesomeIcon icon={showNewPassword ? faEyeSlash : faEye} />
              </span>
            </div>
            {newPasswordError && <div className="text-danger">{newPasswordError}</div>}
          </div>

          {/* Confirm New Password Field */}
          <div className="mb-3">
            <label className="form-label">Confirm New Password</label>
            <div className="input-group">
              <input
                type={showConfirmPassword ? 'text' : 'password'}
                className="form-control form-control-lg bg-white bg-opacity-5"
                value={confirmNewPassword}
                onChange={(e) => {
                  setConfirmNewPassword(e.target.value);
                  setConfirmPasswordError('');
                }}
              />
              <span className="input-group-text" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
              </span>
            </div>
            {confirmPasswordError && <div className="text-danger">{confirmPasswordError}</div>}
          </div>

          {/* Submit Button */}
          <div className="mb-3">
            <button type="submit" className="btn btn-outline-theme btn-lg d-block w-100">
              Change Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ChangePasswordProfile;
