import React, { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppSettings } from "../../config/app-settings.js";
import axios from "axios";
import { toast,ToastContainer } from "react-toastify"; // Ensure `react-toastify` is installed
import 'react-toastify/dist/ReactToastify.css';



function PagesForgetPassword() {
  const theme = useSelector((state) => state.theme.theme);
  const context = useContext(AppSettings);

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    // Hide header and sidebar
    context.setAppHeaderNone(true);
    context.setAppSidebarNone(true);

    // Reset settings on unmount
    return () => {
      context.setAppHeaderNone(false);
      context.setAppSidebarNone(false);
    };
  }, [context]);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic validation
    if (!email) {
      setError("Email address is required.");
      setTimeout(() => setError(""), 3000);
      return;
    } else if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      setTimeout(() => setError(""), 3000);
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/send-forget-password-link`, { email });
    
    console.log("respon=>>",response)
      if (response.status === 200) {

        toast.success('Reset link sent! Please check your email.');
       
      } else {
        throw new Error("Unexpected response from server.");
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message || "Failed to send reset link.",
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="login-app-content">
      <div
        className="row justify-content-center"
        style={{ minHeight: "100vh" }}
      >
        <div className="col-md-12">
          <Link to="/" className="">
            <img
              src={
                theme === "dark"
                  ? "/assets/img/dashboard/dark_logo_web.jpg"
                  : "/assets/img/dashboard/light_logo_web.png"
              }
              style={{
                height: "60px",
              }}
              alt="Brand Logo"
            />
          </Link>
        </div>
        <div className="col-md-4 col-12" style={{ alignItems: "center" }}>
          <form onSubmit={handleSubmit}>
            <h1 className="text-center">Forget Password</h1>
            <div className="text-inverse text-opacity-50 mb-4 text-center">
              Please enter the email address you’d like your password reset
              information sent to.
            </div>

            <div className="mb-3">
              <label className="form-label">
                Enter email address <span className="text-danger">*</span>
              </label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="gqi.example@abc.com"
                className="form-control form-control-lg bg-white bg-opacity-5"
              />
              {error && (
                <div className="text-danger mt-2" style={{ fontSize: "0.9em" }}>
                  {error}
                </div>
              )}
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className="btn btn-outline-theme btn-lg d-block w-100 fw-500"
            >
              {isSubmitting ? "Sending..." : "Request reset link"}
            </button>
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default PagesForgetPassword;
