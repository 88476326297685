import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer'; // Import your root reducer
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Default storage is localStorage for web

// Define persist config
const persistConfig = {
  key: 'root',          // Key for the persisted data
  storage,              // Storage mechanism (localStorage in this case)
  // whitelist: ['someReducer'],  // Optionally, specify which reducers to persist
  // blacklist: ['someOtherReducer'],  // Or specify which reducers not to persist
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the store
const store = configureStore({
  reducer: persistedReducer,
  // Add any middleware or devTools settings if needed
});

// Create a persistor
const persistor = persistStore(store);

// Export both store and persistor
export { store, persistor };
