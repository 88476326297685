import React from 'react';

function UiIcons() {
	return (
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-xl-10">
					<ul className="breadcrumb">
						<li className="breadcrumb-item"><a href="#/">UI KITS</a></li>
						<li className="breadcrumb-item active">ICONS</li>
					</ul>
					
					<h1 className="page-header">
						Icons <small>FontAwesome v6.6.0 with 2,050 Free Icons</small>
					</h1>
					
					<hr className="mb-4" />
		
					<div className="row">
						<div className="col-lg-6">
							<h3 className="mb-2"><b>Solid</b></h3>
							<p className="mb-3">
								Solid type Font Awesome Icon prefix 
								<code>fas fa-*</code>
							</p>
							
							<div className="row mb-5">
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-address-book"></i> <span>address-book</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-address-card"></i> <span>address-card</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-adjust"></i> <span>adjust</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-align-center"></i> <span>align-center</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-align-justify"></i> <span>align-justify</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-align-left"></i> <span>align-left</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-align-right"></i> <span>align-right</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-ambulance"></i> <span>ambulance</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-american-sign-language-interpreting"></i> <span>american-sign-language-interpreting</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-anchor"></i> <span>anchor</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-angle-double-down"></i> <span>angle-double-down</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-angle-double-left"></i> <span>angle-double-left</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-angle-double-right"></i> <span>angle-double-right</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-angle-double-up"></i> <span>angle-double-up</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-angle-down"></i> <span>angle-down</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-angle-left"></i> <span>angle-left</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-angle-right"></i> <span>angle-right</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-angle-up"></i> <span>angle-up</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-archive"></i> <span>archive</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-arrow-alt-circle-down"></i> <span>arrow-alt-circle-down</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-arrow-alt-circle-left"></i> <span>arrow-alt-circle-left</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-arrow-alt-circle-right"></i> <span>arrow-alt-circle-right</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-arrow-alt-circle-up"></i> <span>arrow-alt-circle-up</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-arrow-circle-down"></i> <span>arrow-circle-down</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-arrow-circle-left"></i> <span>arrow-circle-left</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-arrow-circle-right"></i> <span>arrow-circle-right</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-arrow-circle-up"></i> <span>arrow-circle-up</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-arrow-down"></i> <span>arrow-down</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-arrow-left"></i> <span>arrow-left</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-arrow-right"></i> <span>arrow-right</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-arrow-up"></i> <span>arrow-up</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-arrows-alt"></i> <span>arrows-alt</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-arrows-alt-h"></i> <span>arrows-alt-h</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-arrows-alt-v"></i> <span>arrows-alt-v</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-assistive-listening-systems"></i> <span>assistive-listening-systems</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-asterisk"></i> <span>asterisk</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-at"></i> <span>at</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-audio-description"></i> <span>audio-description</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-backward"></i> <span>backward</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-balance-scale"></i> <span>balance-scale</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-ban"></i> <span>ban</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-barcode"></i> <span>barcode</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-bars"></i> <span>bars</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-bath"></i> <span>bath</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-battery-empty"></i> <span>battery-empty</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-battery-full"></i> <span>battery-full</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-battery-half"></i> <span>battery-half</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-battery-quarter"></i> <span>battery-quarter</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-battery-three-quarters"></i> <span>battery-three-quarters</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-bed"></i> <span>bed</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-beer"></i> <span>beer</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-bell"></i> <span>bell</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-bell-slash"></i> <span>bell-slash</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-bicycle"></i> <span>bicycle</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-binoculars"></i> <span>binoculars</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-birthday-cake"></i> <span>birthday-cake</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-blind"></i> <span>blind</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-bold"></i> <span>bold</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-bolt"></i> <span>bolt</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-bomb"></i> <span>bomb</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-book"></i> <span>book</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-bookmark"></i> <span>bookmark</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-braille"></i> <span>braille</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-briefcase"></i> <span>briefcase</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-bug"></i> <span>bug</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-building"></i> <span>building</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-bullhorn"></i> <span>bullhorn</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-bullseye"></i> <span>bullseye</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-bus"></i> <span>bus</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-calculator"></i> <span>calculator</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-calendar"></i> <span>calendar</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-calendar-alt"></i> <span>calendar-alt</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-calendar-check"></i> <span>calendar-check</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-calendar-minus"></i> <span>calendar-minus</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-calendar-plus"></i> <span>calendar-plus</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-calendar-times"></i> <span>calendar-times</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-camera"></i> <span>camera</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-camera-retro"></i> <span>camera-retro</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-car"></i> <span>car</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-caret-down"></i> <span>caret-down</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-caret-left"></i> <span>caret-left</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-caret-right"></i> <span>caret-right</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-caret-square-down"></i> <span>caret-square-down</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-caret-square-left"></i> <span>caret-square-left</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-caret-square-right"></i> <span>caret-square-right</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-caret-square-up"></i> <span>caret-square-up</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-caret-up"></i> <span>caret-up</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-cart-arrow-down"></i> <span>cart-arrow-down</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-cart-plus"></i> <span>cart-plus</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-certificate"></i> <span>certificate</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-chart-area"></i> <span>chart-area</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-chart-bar"></i> <span>chart-bar</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-chart-line"></i> <span>chart-line</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-chart-pie"></i> <span>chart-pie</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-check"></i> <span>check</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-check-circle"></i> <span>check-circle</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-check-square"></i> <span>check-square</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-chevron-circle-down"></i> <span>chevron-circle-down</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-chevron-circle-left"></i> <span>chevron-circle-left</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-chevron-circle-right"></i> <span>chevron-circle-right</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-chevron-circle-up"></i> <span>chevron-circle-up</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-chevron-down"></i> <span>chevron-down</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-chevron-left"></i> <span>chevron-left</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-chevron-right"></i> <span>chevron-right</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-chevron-up"></i> <span>chevron-up</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-child"></i> <span>child</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-circle"></i> <span>circle</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-circle-notch"></i> <span>circle-notch</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-clipboard"></i> <span>clipboard</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-clock"></i> <span>clock</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-clone"></i> <span>clone</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-closed-captioning"></i> <span>closed-captioning</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-cloud"></i> <span>cloud</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-code"></i> <span>code</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-code-branch"></i> <span>code-branch</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-coffee"></i> <span>coffee</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-cog"></i> <span>cog</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-cogs"></i> <span>cogs</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-columns"></i> <span>columns</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-comment"></i> <span>comment</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-comment-alt"></i> <span>comment-alt</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-comments"></i> <span>comments</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-compass"></i> <span>compass</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-compress"></i> <span>compress</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-copy"></i> <span>copy</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-copyright"></i> <span>copyright</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-credit-card"></i> <span>credit-card</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-crop"></i> <span>crop</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-crosshairs"></i> <span>crosshairs</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-cube"></i> <span>cube</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-cubes"></i> <span>cubes</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-cut"></i> <span>cut</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-database"></i> <span>database</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-deaf"></i> <span>deaf</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-dollar-sign"></i> <span>dollar-sign</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-dot-circle"></i> <span>dot-circle</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-download"></i> <span>download</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-edit"></i> <span>edit</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-eject"></i> <span>eject</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-ellipsis-h"></i> <span>ellipsis-h</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-ellipsis-v"></i> <span>ellipsis-v</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-envelope"></i> <span>envelope</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-envelope-open"></i> <span>envelope-open</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-envelope-square"></i> <span>envelope-square</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-eraser"></i> <span>eraser</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-euro-sign"></i> <span>euro-sign</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-exchange-alt"></i> <span>exchange-alt</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-exclamation"></i> <span>exclamation</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-exclamation-circle"></i> <span>exclamation-circle</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-exclamation-triangle"></i> <span>exclamation-triangle</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-expand"></i> <span>expand</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-expand-arrows-alt"></i> <span>expand-arrows-alt</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-external-link-alt"></i> <span>external-link-alt</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-external-link-square-alt"></i> <span>external-link-square-alt</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-eye"></i> <span>eye</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-eye-dropper"></i> <span>eye-dropper</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-eye-slash"></i> <span>eye-slash</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-fast-backward"></i> <span>fast-backward</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-fast-forward"></i> <span>fast-forward</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-fax"></i> <span>fax</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-female"></i> <span>female</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-fighter-jet"></i> <span>fighter-jet</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-file"></i> <span>file</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-file-alt"></i> <span>file-alt</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-file-archive"></i> <span>file-archive</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-file-audio"></i> <span>file-audio</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-file-code"></i> <span>file-code</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-file-excel"></i> <span>file-excel</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-file-image"></i> <span>file-image</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-file-pdf"></i> <span>file-pdf</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-file-powerpoint"></i> <span>file-powerpoint</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-file-video"></i> <span>file-video</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-file-word"></i> <span>file-word</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-film"></i> <span>film</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-filter"></i> <span>filter</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-fire"></i> <span>fire</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-fire-extinguisher"></i> <span>fire-extinguisher</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-flag"></i> <span>flag</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-flag-checkered"></i> <span>flag-checkered</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-flask"></i> <span>flask</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-folder"></i> <span>folder</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-folder-open"></i> <span>folder-open</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-font"></i> <span>font</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-forward"></i> <span>forward</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-frown"></i> <span>frown</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-futbol"></i> <span>futbol</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-gamepad"></i> <span>gamepad</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-gavel"></i> <span>gavel</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-gem"></i> <span>gem</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-genderless"></i> <span>genderless</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-gift"></i> <span>gift</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-glass-martini"></i> <span>glass-martini</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-globe"></i> <span>globe</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-graduation-cap"></i> <span>graduation-cap</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-h-square"></i> <span>h-square</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-hand-lizard"></i> <span>hand-lizard</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-hand-paper"></i> <span>hand-paper</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-hand-peace"></i> <span>hand-peace</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-hand-point-down"></i> <span>hand-point-down</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-hand-point-left"></i> <span>hand-point-left</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-hand-point-right"></i> <span>hand-point-right</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-hand-point-up"></i> <span>hand-point-up</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-hand-pointer"></i> <span>hand-pointer</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-hand-rock"></i> <span>hand-rock</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-hand-scissors"></i> <span>hand-scissors</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-hand-spock"></i> <span>hand-spock</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-handshake"></i> <span>handshake</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-hashtag"></i> <span>hashtag</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-hdd"></i> <span>hdd</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-heading"></i> <span>heading</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-headphones"></i> <span>headphones</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-heart"></i> <span>heart</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-heartbeat"></i> <span>heartbeat</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-history"></i> <span>history</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-home"></i> <span>home</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-hospital"></i> <span>hospital</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-hourglass"></i> <span>hourglass</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-hourglass-end"></i> <span>hourglass-end</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-hourglass-half"></i> <span>hourglass-half</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-hourglass-start"></i> <span>hourglass-start</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-i-cursor"></i> <span>i-cursor</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-id-badge"></i> <span>id-badge</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-id-card"></i> <span>id-card</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-image"></i> <span>image</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-images"></i> <span>images</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-inbox"></i> <span>inbox</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-indent"></i> <span>indent</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-industry"></i> <span>industry</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-info"></i> <span>info</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-info-circle"></i> <span>info-circle</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-italic"></i> <span>italic</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-key"></i> <span>key</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-keyboard"></i> <span>keyboard</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-language"></i> <span>language</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-laptop"></i> <span>laptop</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-leaf"></i> <span>leaf</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-lemon"></i> <span>lemon</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-level-down-alt"></i> <span>level-down-alt</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-level-up-alt"></i> <span>level-up-alt</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-life-ring"></i> <span>life-ring</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-lightbulb"></i> <span>lightbulb</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-link"></i> <span>link</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-lira-sign"></i> <span>lira-sign</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-list"></i> <span>list</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-list-alt"></i> <span>list-alt</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-list-ol"></i> <span>list-ol</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-list-ul"></i> <span>list-ul</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-location-arrow"></i> <span>location-arrow</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-lock"></i> <span>lock</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-lock-open"></i> <span>lock-open</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-long-arrow-alt-down"></i> <span>long-arrow-alt-down</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-long-arrow-alt-left"></i> <span>long-arrow-alt-left</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-long-arrow-alt-right"></i> <span>long-arrow-alt-right</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-long-arrow-alt-up"></i> <span>long-arrow-alt-up</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-low-vision"></i> <span>low-vision</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-magic"></i> <span>magic</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-magnet"></i> <span>magnet</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-male"></i> <span>male</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-map"></i> <span>map</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-map-marker"></i> <span>map-marker</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-map-marker-alt"></i> <span>map-marker-alt</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-map-pin"></i> <span>map-pin</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-map-signs"></i> <span>map-signs</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-mars"></i> <span>mars</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-mars-double"></i> <span>mars-double</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-mars-stroke"></i> <span>mars-stroke</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-mars-stroke-h"></i> <span>mars-stroke-h</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-mars-stroke-v"></i> <span>mars-stroke-v</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-medkit"></i> <span>medkit</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-meh"></i> <span>meh</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-mercury"></i> <span>mercury</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-microchip"></i> <span>microchip</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-microphone"></i> <span>microphone</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-microphone-slash"></i> <span>microphone-slash</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-minus"></i> <span>minus</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-minus-circle"></i> <span>minus-circle</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-minus-square"></i> <span>minus-square</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-mobile-alt"></i> <span>mobile-alt</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-money-bill-alt"></i> <span>money-bill-alt</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-moon"></i> <span>moon</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-motorcycle"></i> <span>motorcycle</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-mouse-pointer"></i> <span>mouse-pointer</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-music"></i> <span>music</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-neuter"></i> <span>neuter</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-newspaper"></i> <span>newspaper</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-object-group"></i> <span>object-group</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-object-ungroup"></i> <span>object-ungroup</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-outdent"></i> <span>outdent</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-paint-brush"></i> <span>paint-brush</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-paper-plane"></i> <span>paper-plane</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-paperclip"></i> <span>paperclip</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-paragraph"></i> <span>paragraph</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-paste"></i> <span>paste</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-pause"></i> <span>pause</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-pause-circle"></i> <span>pause-circle</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-paw"></i> <span>paw</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-pen-square"></i> <span>pen-square</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-pencil-alt"></i> <span>pencil-alt</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-percent"></i> <span>percent</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-phone"></i> <span>phone</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-phone-square"></i> <span>phone-square</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-phone-volume"></i> <span>phone-volume</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-plane"></i> <span>plane</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-play"></i> <span>play</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-play-circle"></i> <span>play-circle</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-plug"></i> <span>plug</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-plus"></i> <span>plus</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-plus-circle"></i> <span>plus-circle</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-plus-square"></i> <span>plus-square</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-podcast"></i> <span>podcast</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-pound-sign"></i> <span>pound-sign</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-power-off"></i> <span>power-off</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-print"></i> <span>print</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-puzzle-piece"></i> <span>puzzle-piece</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-qrcode"></i> <span>qrcode</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-question"></i> <span>question</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-question-circle"></i> <span>question-circle</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-quote-left"></i> <span>quote-left</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-quote-right"></i> <span>quote-right</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-random"></i> <span>random</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-recycle"></i> <span>recycle</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-redo"></i> <span>redo</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-redo-alt"></i> <span>redo-alt</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-registered"></i> <span>registered</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-reply"></i> <span>reply</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-reply-all"></i> <span>reply-all</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-retweet"></i> <span>retweet</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-road"></i> <span>road</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-rocket"></i> <span>rocket</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-rss"></i> <span>rss</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-rss-square"></i> <span>rss-square</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-ruble-sign"></i> <span>ruble-sign</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-rupee-sign"></i> <span>rupee-sign</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-save"></i> <span>save</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-search"></i> <span>search</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-search-minus"></i> <span>search-minus</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-search-plus"></i> <span>search-plus</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-server"></i> <span>server</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-share"></i> <span>share</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-share-alt"></i> <span>share-alt</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-share-alt-square"></i> <span>share-alt-square</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-share-square"></i> <span>share-square</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-shekel-sign"></i> <span>shekel-sign</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-shield-alt"></i> <span>shield-alt</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-ship"></i> <span>ship</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-shopping-bag"></i> <span>shopping-bag</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-shopping-basket"></i> <span>shopping-basket</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-shopping-cart"></i> <span>shopping-cart</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-shower"></i> <span>shower</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-sign-in-alt"></i> <span>sign-in-alt</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-sign-language"></i> <span>sign-language</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-sign-out-alt"></i> <span>sign-out-alt</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-signal"></i> <span>signal</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-sitemap"></i> <span>sitemap</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-sliders-h"></i> <span>sliders-h</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-smile"></i> <span>smile</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-snowflake"></i> <span>snowflake</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-sort"></i> <span>sort</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-sort-alpha-down"></i> <span>sort-alpha-down</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-sort-alpha-up"></i> <span>sort-alpha-up</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-sort-amount-down"></i> <span>sort-amount-down</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-sort-amount-up"></i> <span>sort-amount-up</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-sort-down"></i> <span>sort-down</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-sort-numeric-down"></i> <span>sort-numeric-down</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-sort-numeric-up"></i> <span>sort-numeric-up</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-sort-up"></i> <span>sort-up</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-space-shuttle"></i> <span>space-shuttle</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-spinner"></i> <span>spinner</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-square"></i> <span>square</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-star"></i> <span>star</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-star-half"></i> <span>star-half</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-step-backward"></i> <span>step-backward</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-step-forward"></i> <span>step-forward</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-stethoscope"></i> <span>stethoscope</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-sticky-note"></i> <span>sticky-note</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-stop"></i> <span>stop</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-stop-circle"></i> <span>stop-circle</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-stopwatch"></i> <span>stopwatch</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-street-view"></i> <span>street-view</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-strikethrough"></i> <span>strikethrough</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-subscript"></i> <span>subscript</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-subway"></i> <span>subway</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-suitcase"></i> <span>suitcase</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-sun"></i> <span>sun</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-superscript"></i> <span>superscript</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-sync"></i> <span>sync</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-sync-alt"></i> <span>sync-alt</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-table"></i> <span>table</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-tablet-alt"></i> <span>tablet-alt</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-tag"></i> <span>tag</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-tags"></i> <span>tags</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-tasks"></i> <span>tasks</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-taxi"></i> <span>taxi</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-terminal"></i> <span>terminal</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-text-height"></i> <span>text-height</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-text-width"></i> <span>text-width</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-th"></i> <span>th</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-th-large"></i> <span>th-large</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-th-list"></i> <span>th-list</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-thermometer-empty"></i> <span>thermometer-empty</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-thermometer-full"></i> <span>thermometer-full</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-thermometer-half"></i> <span>thermometer-half</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-thermometer-quarter"></i> <span>thermometer-quarter</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-thermometer-three-quarters"></i> <span>thermometer-three-quarters</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-thumbs-down"></i> <span>thumbs-down</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-thumbs-up"></i> <span>thumbs-up</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-thumbtack"></i> <span>thumbtack</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-ticket-alt"></i> <span>ticket-alt</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-times"></i> <span>times</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-times-circle"></i> <span>times-circle</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-tint"></i> <span>tint</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-toggle-off"></i> <span>toggle-off</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-toggle-on"></i> <span>toggle-on</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-trademark"></i> <span>trademark</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-train"></i> <span>train</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-transgender"></i> <span>transgender</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-transgender-alt"></i> <span>transgender-alt</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-trash"></i> <span>trash</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-trash-alt"></i> <span>trash-alt</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-tree"></i> <span>tree</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-trophy"></i> <span>trophy</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-truck"></i> <span>truck</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-tty"></i> <span>tty</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-tv"></i> <span>tv</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-umbrella"></i> <span>umbrella</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-underline"></i> <span>underline</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-undo"></i> <span>undo</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-undo-alt"></i> <span>undo-alt</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-universal-access"></i> <span>universal-access</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-university"></i> <span>university</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-unlink"></i> <span>unlink</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-unlock"></i> <span>unlock</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-unlock-alt"></i> <span>unlock-alt</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-upload"></i> <span>upload</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-user"></i> <span>user</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-user-circle"></i> <span>user-circle</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-user-md"></i> <span>user-md</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-user-plus"></i> <span>user-plus</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-user-secret"></i> <span>user-secret</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-user-times"></i> <span>user-times</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-users"></i> <span>users</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-utensil-spoon"></i> <span>utensil-spoon</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-utensils"></i> <span>utensils</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-venus"></i> <span>venus</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-venus-double"></i> <span>venus-double</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-venus-mars"></i> <span>venus-mars</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-video"></i> <span>video</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-volume-down"></i> <span>volume-down</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-volume-off"></i> <span>volume-off</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-volume-up"></i> <span>volume-up</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-wheelchair"></i> <span>wheelchair</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-wifi"></i> <span>wifi</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-window-close"></i> <span>window-close</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-window-maximize"></i> <span>window-maximize</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-window-minimize"></i> <span>window-minimize</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-window-restore"></i> <span>window-restore</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-won-sign"></i> <span>won-sign</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-wrench"></i> <span>wrench</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fas fa-lg fa-fw me-2 fa-yen-sign"></i> <span>yen-sign</span></div>
							</div>
						</div>
						<div className="col-lg-6">
							<h3 className="mb-2"><b>Regular</b></h3>
							<p className="mb-3">
								Regular type Font Awesome Icon prefix 
								<code>far fa-*</code>
							</p>
							
							<div className="row mb-5">
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-address-book"></i> <span>address-book</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-address-card"></i> <span>address-card</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-arrow-alt-circle-down"></i> <span>arrow-alt-circle-down</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-arrow-alt-circle-left"></i> <span>arrow-alt-circle-left</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-arrow-alt-circle-right"></i> <span>arrow-alt-circle-right</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-arrow-alt-circle-up"></i> <span>arrow-alt-circle-up</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-bell"></i> <span>bell</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-bell-slash"></i> <span>bell-slash</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-bookmark"></i> <span>bookmark</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-building"></i> <span>building</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-calendar"></i> <span>calendar</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-calendar-alt"></i> <span>calendar-alt</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-calendar-check"></i> <span>calendar-check</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-calendar-minus"></i> <span>calendar-minus</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-calendar-plus"></i> <span>calendar-plus</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-calendar-times"></i> <span>calendar-times</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-caret-square-down"></i> <span>caret-square-down</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-caret-square-left"></i> <span>caret-square-left</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-caret-square-right"></i> <span>caret-square-right</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-caret-square-up"></i> <span>caret-square-up</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-chart-bar"></i> <span>chart-bar</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-check-circle"></i> <span>check-circle</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-check-square"></i> <span>check-square</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-circle"></i> <span>circle</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-clipboard"></i> <span>clipboard</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-clock"></i> <span>clock</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-clone"></i> <span>clone</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-closed-captioning"></i> <span>closed-captioning</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-comment"></i> <span>comment</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-comment-alt"></i> <span>comment-alt</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-comments"></i> <span>comments</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-compass"></i> <span>compass</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-copy"></i> <span>copy</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-copyright"></i> <span>copyright</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-credit-card"></i> <span>credit-card</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-dot-circle"></i> <span>dot-circle</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-edit"></i> <span>edit</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-envelope"></i> <span>envelope</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-envelope-open"></i> <span>envelope-open</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-eye-slash"></i> <span>eye-slash</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-file"></i> <span>file</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-file-alt"></i> <span>file-alt</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-file-archive"></i> <span>file-archive</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-file-audio"></i> <span>file-audio</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-file-code"></i> <span>file-code</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-file-excel"></i> <span>file-excel</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-file-image"></i> <span>file-image</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-file-pdf"></i> <span>file-pdf</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-file-powerpoint"></i> <span>file-powerpoint</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-file-video"></i> <span>file-video</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-file-word"></i> <span>file-word</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-flag"></i> <span>flag</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-folder"></i> <span>folder</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-folder-open"></i> <span>folder-open</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-frown"></i> <span>frown</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-futbol"></i> <span>futbol</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-gem"></i> <span>gem</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-hand-lizard"></i> <span>hand-lizard</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-hand-paper"></i> <span>hand-paper</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-hand-peace"></i> <span>hand-peace</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-hand-point-down"></i> <span>hand-point-down</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-hand-point-left"></i> <span>hand-point-left</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-hand-point-right"></i> <span>hand-point-right</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-hand-point-up"></i> <span>hand-point-up</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-hand-pointer"></i> <span>hand-pointer</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-hand-rock"></i> <span>hand-rock</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-hand-scissors"></i> <span>hand-scissors</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-hand-spock"></i> <span>hand-spock</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-handshake"></i> <span>handshake</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-hdd"></i> <span>hdd</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-heart"></i> <span>heart</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-hospital"></i> <span>hospital</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-hourglass"></i> <span>hourglass</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-id-badge"></i> <span>id-badge</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-id-card"></i> <span>id-card</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-image"></i> <span>image</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-images"></i> <span>images</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-keyboard"></i> <span>keyboard</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-lemon"></i> <span>lemon</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-life-ring"></i> <span>life-ring</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-lightbulb"></i> <span>lightbulb</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-list-alt"></i> <span>list-alt</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-map"></i> <span>map</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-meh"></i> <span>meh</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-minus-square"></i> <span>minus-square</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-money-bill-alt"></i> <span>money-bill-alt</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-moon"></i> <span>moon</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-newspaper"></i> <span>newspaper</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-object-group"></i> <span>object-group</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-object-ungroup"></i> <span>object-ungroup</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-paper-plane"></i> <span>paper-plane</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-pause-circle"></i> <span>pause-circle</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-play-circle"></i> <span>play-circle</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-plus-square"></i> <span>plus-square</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-question-circle"></i> <span>question-circle</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-registered"></i> <span>registered</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-save"></i> <span>save</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-share-square"></i> <span>share-square</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-smile"></i> <span>smile</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-snowflake"></i> <span>snowflake</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-square"></i> <span>square</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-star"></i> <span>star</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-star-half"></i> <span>star-half</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-sticky-note"></i> <span>sticky-note</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-stop-circle"></i> <span>stop-circle</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-sun"></i> <span>sun</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-thumbs-down"></i> <span>thumbs-down</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-thumbs-up"></i> <span>thumbs-up</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-times-circle"></i> <span>times-circle</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-trash-alt"></i> <span>trash-alt</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-user"></i> <span>user</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-user-circle"></i> <span>user-circle</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-window-close"></i> <span>window-close</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-window-maximize"></i> <span>window-maximize</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-window-minimize"></i> <span>window-minimize</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="far fa-lg fa-fw me-2 fa-window-restore"></i> <span>window-restore</span></div>
							</div>
				
							<h3 className="mb-2"><b>Brands</b></h3>
							<p className="mb-3">
								Brand type Font Awesome Icon prefix 
								<code>fab fa-*</code>
							</p>
							
							<div className="row">
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-500px"></i> <span>500px</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-accessible-icon"></i> <span>accessible-icon</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-accusoft"></i> <span>accusoft</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-adn"></i> <span>adn</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-adversal"></i> <span>adversal</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-affiliatetheme"></i> <span>affiliatetheme</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-algolia"></i> <span>algolia</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-amazon"></i> <span>amazon</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-amazon-pay"></i> <span>amazon-pay</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-amilia"></i> <span>amilia</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-android"></i> <span>android</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-angellist"></i> <span>angellist</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-angrycreative"></i> <span>angrycreative</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-angular"></i> <span>angular</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-app-store"></i> <span>app-store</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-app-store-ios"></i> <span>app-store-ios</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-apper"></i> <span>apper</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-apple"></i> <span>apple</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-apple-pay"></i> <span>apple-pay</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-asymmetrik"></i> <span>asymmetrik</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-audible"></i> <span>audible</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-autoprefixer"></i> <span>autoprefixer</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-avianex"></i> <span>avianex</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-aviato"></i> <span>aviato</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-aws"></i> <span>aws</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-bandcamp"></i> <span>bandcamp</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-behance"></i> <span>behance</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-behance-square"></i> <span>behance-square</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-bimobject"></i> <span>bimobject</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-bitbucket"></i> <span>bitbucket</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-bitcoin"></i> <span>bitcoin</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-bity"></i> <span>bity</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-black-tie"></i> <span>black-tie</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-blackberry"></i> <span>blackberry</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-blogger"></i> <span>blogger</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-blogger-b"></i> <span>blogger-b</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-bluetooth"></i> <span>bluetooth</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-bluetooth-b"></i> <span>bluetooth-b</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-btc"></i> <span>btc</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-buromobelexperte"></i> <span>buromobelexperte</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-buysellads"></i> <span>buysellads</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-cc-amazon-pay"></i> <span>cc-amazon-pay</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-cc-amex"></i> <span>cc-amex</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-cc-apple-pay"></i> <span>cc-apple-pay</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-cc-diners-club"></i> <span>cc-diners-club</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-cc-discover"></i> <span>cc-discover</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-cc-jcb"></i> <span>cc-jcb</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-cc-mastercard"></i> <span>cc-mastercard</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-cc-paypal"></i> <span>cc-paypal</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-cc-stripe"></i> <span>cc-stripe</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-cc-visa"></i> <span>cc-visa</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-centercode"></i> <span>centercode</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-chrome"></i> <span>chrome</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-cloudscale"></i> <span>cloudscale</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-cloudsmith"></i> <span>cloudsmith</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-cloudversify"></i> <span>cloudversify</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-codepen"></i> <span>codepen</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-codiepie"></i> <span>codiepie</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-connectdevelop"></i> <span>connectdevelop</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-contao"></i> <span>contao</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-cpanel"></i> <span>cpanel</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-creative-commons"></i> <span>creative-commons</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-css3"></i> <span>css3</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-css3-alt"></i> <span>css3-alt</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-cuttlefish"></i> <span>cuttlefish</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-d-and-d"></i> <span>d-and-d</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-dashcube"></i> <span>dashcube</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-delicious"></i> <span>delicious</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-deploydog"></i> <span>deploydog</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-deskpro"></i> <span>deskpro</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-deviantart"></i> <span>deviantart</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-digg"></i> <span>digg</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-digital-ocean"></i> <span>digital-ocean</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-discord"></i> <span>discord</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-discourse"></i> <span>discourse</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-dochub"></i> <span>dochub</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-docker"></i> <span>docker</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-draft2digital"></i> <span>draft2digital</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-dribbble"></i> <span>dribbble</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-dribbble-square"></i> <span>dribbble-square</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-dropbox"></i> <span>dropbox</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-drupal"></i> <span>drupal</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-dyalog"></i> <span>dyalog</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-earlybirds"></i> <span>earlybirds</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-edge"></i> <span>edge</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-elementor"></i> <span>elementor</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-ember"></i> <span>ember</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-empire"></i> <span>empire</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-envira"></i> <span>envira</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-erlang"></i> <span>erlang</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-ethereum"></i> <span>ethereum</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-etsy"></i> <span>etsy</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-expeditedssl"></i> <span>expeditedssl</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-facebook"></i> <span>facebook</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-facebook-f"></i> <span>facebook-f</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-facebook-messenger"></i> <span>facebook-messenger</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-facebook-square"></i> <span>facebook-square</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-firefox"></i> <span>firefox</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-first-order"></i> <span>first-order</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-firstdraft"></i> <span>firstdraft</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-flickr"></i> <span>flickr</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-fly"></i> <span>fly</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-font-awesome"></i> <span>font-awesome</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-font-awesome-alt"></i> <span>font-awesome-alt</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-font-awesome-flag"></i> <span>font-awesome-flag</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-fonticons"></i> <span>fonticons</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-fonticons-fi"></i> <span>fonticons-fi</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-fort-awesome"></i> <span>fort-awesome</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-fort-awesome-alt"></i> <span>fort-awesome-alt</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-forumbee"></i> <span>forumbee</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-foursquare"></i> <span>foursquare</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-free-code-camp"></i> <span>free-code-camp</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-freebsd"></i> <span>freebsd</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-get-pocket"></i> <span>get-pocket</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-gg"></i> <span>gg</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-gg-circle"></i> <span>gg-circle</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-git"></i> <span>git</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-git-square"></i> <span>git-square</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-github"></i> <span>github</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-github-alt"></i> <span>github-alt</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-github-square"></i> <span>github-square</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-gitkraken"></i> <span>gitkraken</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-gitlab"></i> <span>gitlab</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-gitter"></i> <span>gitter</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-glide"></i> <span>glide</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-glide-g"></i> <span>glide-g</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-gofore"></i> <span>gofore</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-goodreads"></i> <span>goodreads</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-goodreads-g"></i> <span>goodreads-g</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-google"></i> <span>google</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-google-drive"></i> <span>google-drive</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-google-play"></i> <span>google-play</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-google-plus"></i> <span>google-plus</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-google-plus-g"></i> <span>google-plus-g</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-google-plus-square"></i> <span>google-plus-square</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-google-wallet"></i> <span>google-wallet</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-gratipay"></i> <span>gratipay</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-grav"></i> <span>grav</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-gripfire"></i> <span>gripfire</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-grunt"></i> <span>grunt</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-gulp"></i> <span>gulp</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-hacker-news"></i> <span>hacker-news</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-hacker-news-square"></i> <span>hacker-news-square</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-hire-a-helper"></i> <span>hire-a-helper</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-hooli"></i> <span>hooli</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-hotjar"></i> <span>hotjar</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-houzz"></i> <span>houzz</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-html5"></i> <span>html5</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-hubspot"></i> <span>hubspot</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-imdb"></i> <span>imdb</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-instagram"></i> <span>instagram</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-internet-explorer"></i> <span>internet-explorer</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-ioxhost"></i> <span>ioxhost</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-itunes"></i> <span>itunes</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-itunes-note"></i> <span>itunes-note</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-jenkins"></i> <span>jenkins</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-joget"></i> <span>joget</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-joomla"></i> <span>joomla</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-js"></i> <span>js</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-js-square"></i> <span>js-square</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-jsfiddle"></i> <span>jsfiddle</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-keycdn"></i> <span>keycdn</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-kickstarter"></i> <span>kickstarter</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-kickstarter-k"></i> <span>kickstarter-k</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-korvue"></i> <span>korvue</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-laravel"></i> <span>laravel</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-lastfm"></i> <span>lastfm</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-lastfm-square"></i> <span>lastfm-square</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-leanpub"></i> <span>leanpub</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-less"></i> <span>less</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-line"></i> <span>line</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-linkedin"></i> <span>linkedin</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-linkedin-in"></i> <span>linkedin-in</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-linode"></i> <span>linode</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-linux"></i> <span>linux</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-lyft"></i> <span>lyft</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-magento"></i> <span>magento</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-maxcdn"></i> <span>maxcdn</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-medapps"></i> <span>medapps</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-medium"></i> <span>medium</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-medium-m"></i> <span>medium-m</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-medrt"></i> <span>medrt</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-meetup"></i> <span>meetup</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-microsoft"></i> <span>microsoft</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-mix"></i> <span>mix</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-mixcloud"></i> <span>mixcloud</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-mizuni"></i> <span>mizuni</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-modx"></i> <span>modx</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-monero"></i> <span>monero</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-napster"></i> <span>napster</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-node"></i> <span>node</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-node-js"></i> <span>node-js</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-npm"></i> <span>npm</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-ns8"></i> <span>ns8</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-nutritionix"></i> <span>nutritionix</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-odnoklassniki"></i> <span>odnoklassniki</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-odnoklassniki-square"></i> <span>odnoklassniki-square</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-opencart"></i> <span>opencart</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-openid"></i> <span>openid</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-opera"></i> <span>opera</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-optin-monster"></i> <span>optin-monster</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-osi"></i> <span>osi</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-page4"></i> <span>page4</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-pagelines"></i> <span>pagelines</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-palfed"></i> <span>palfed</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-patreon"></i> <span>patreon</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-paypal"></i> <span>paypal</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-periscope"></i> <span>periscope</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-phabricator"></i> <span>phabricator</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-phoenix-framework"></i> <span>phoenix-framework</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-pied-piper"></i> <span>pied-piper</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-pied-piper-alt"></i> <span>pied-piper-alt</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-pied-piper-pp"></i> <span>pied-piper-pp</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-pinterest"></i> <span>pinterest</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-pinterest-p"></i> <span>pinterest-p</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-pinterest-square"></i> <span>pinterest-square</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-playstation"></i> <span>playstation</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-product-hunt"></i> <span>product-hunt</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-pushed"></i> <span>pushed</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-python"></i> <span>python</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-qq"></i> <span>qq</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-quora"></i> <span>quora</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-ravelry"></i> <span>ravelry</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-react"></i> <span>react</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-rebel"></i> <span>rebel</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-red-river"></i> <span>red-river</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-reddit"></i> <span>reddit</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-reddit-alien"></i> <span>reddit-alien</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-reddit-square"></i> <span>reddit-square</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-renren"></i> <span>renren</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-replyd"></i> <span>replyd</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-resolving"></i> <span>resolving</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-rocketchat"></i> <span>rocketchat</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-rockrms"></i> <span>rockrms</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-safari"></i> <span>safari</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-sass"></i> <span>sass</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-schlix"></i> <span>schlix</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-scribd"></i> <span>scribd</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-searchengin"></i> <span>searchengin</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-sellcast"></i> <span>sellcast</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-sellsy"></i> <span>sellsy</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-servicestack"></i> <span>servicestack</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-shirtsinbulk"></i> <span>shirtsinbulk</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-simplybuilt"></i> <span>simplybuilt</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-sistrix"></i> <span>sistrix</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-skyatlas"></i> <span>skyatlas</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-skype"></i> <span>skype</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-slack"></i> <span>slack</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-slack-hash"></i> <span>slack-hash</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-slideshare"></i> <span>slideshare</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-snapchat"></i> <span>snapchat</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-snapchat-ghost"></i> <span>snapchat-ghost</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-snapchat-square"></i> <span>snapchat-square</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-soundcloud"></i> <span>soundcloud</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-speakap"></i> <span>speakap</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-spotify"></i> <span>spotify</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-stack-exchange"></i> <span>stack-exchange</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-stack-overflow"></i> <span>stack-overflow</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-staylinked"></i> <span>staylinked</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-steam"></i> <span>steam</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-steam-square"></i> <span>steam-square</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-steam-symbol"></i> <span>steam-symbol</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-sticker-mule"></i> <span>sticker-mule</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-strava"></i> <span>strava</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-stripe"></i> <span>stripe</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-stripe-s"></i> <span>stripe-s</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-studiovinari"></i> <span>studiovinari</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-stumbleupon"></i> <span>stumbleupon</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-stumbleupon-circle"></i> <span>stumbleupon-circle</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-superpowers"></i> <span>superpowers</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-supple"></i> <span>supple</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-telegram"></i> <span>telegram</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-telegram-plane"></i> <span>telegram-plane</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-tencent-weibo"></i> <span>tencent-weibo</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-themeisle"></i> <span>themeisle</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-trello"></i> <span>trello</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-tumblr"></i> <span>tumblr</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-tumblr-square"></i> <span>tumblr-square</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-twitch"></i> <span>twitch</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-twitter"></i> <span>twitter</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-twitter-square"></i> <span>twitter-square</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-typo3"></i> <span>typo3</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-uber"></i> <span>uber</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-uikit"></i> <span>uikit</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-uniregistry"></i> <span>uniregistry</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-untappd"></i> <span>untappd</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-usb"></i> <span>usb</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-ussunnah"></i> <span>ussunnah</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-vaadin"></i> <span>vaadin</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-viacoin"></i> <span>viacoin</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-viadeo"></i> <span>viadeo</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-viadeo-square"></i> <span>viadeo-square</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-viber"></i> <span>viber</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-vimeo"></i> <span>vimeo</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-vimeo-square"></i> <span>vimeo-square</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-vimeo-v"></i> <span>vimeo-v</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-vine"></i> <span>vine</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-vk"></i> <span>vk</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-vnv"></i> <span>vnv</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-vuejs"></i> <span>vuejs</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-weibo"></i> <span>weibo</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-weixin"></i> <span>weixin</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-whatsapp"></i> <span>whatsapp</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-whatsapp-square"></i> <span>whatsapp-square</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-whmcs"></i> <span>whmcs</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-wikipedia-w"></i> <span>wikipedia-w</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-windows"></i> <span>windows</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-wordpress"></i> <span>wordpress</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-wordpress-simple"></i> <span>wordpress-simple</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-wpbeginner"></i> <span>wpbeginner</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-wpexplorer"></i> <span>wpexplorer</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-wpforms"></i> <span>wpforms</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-xbox"></i> <span>xbox</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-xing"></i> <span>xing</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-xing-square"></i> <span>xing-square</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-y-combinator"></i> <span>y-combinator</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-yahoo"></i> <span>yahoo</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-yandex"></i> <span>yandex</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-yandex-international"></i> <span>yandex-international</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-yelp"></i> <span>yelp</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-yoast"></i> <span>yoast</span></div>
								<div className="col-md-6 col-sm-6 col-xs-6 mb-1 text-truncate"><i className="fab fa-lg fa-fw me-2 fa-youtube"></i> <span>youtube</span></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default UiIcons;