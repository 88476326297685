// slices/themeSlice.js
import { createSlice } from '@reduxjs/toolkit';

const themeSlice = createSlice({
  name: 'example',
  initialState: { theme:"dark" },
  reducers: {
     setThemes: (state, action) => {
        state.theme = action.payload;
      },
    
   
  },
});

// Export actions and reducer
export const { increment, decrement, incrementByAmount,setThemes } = themeSlice.actions;
export default themeSlice.reducer;
