import React, { useState, useEffect } from "react";
import { Card } from "../../../components/card/card.jsx";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import Sidebar from "../../../components/sidebar/sidebar.jsx";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Users.css";
import { useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import { CountryDropdown } from "react-country-region-selector"; // Import a country dropdown component

function ManageUsers() {


  const [show, setShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [addShow, setAddShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [users, setUsers] = useState([]); // State to store users
  const [subscriptions, setSubscriptions] = useState([]); // State to store subscription options
  const [currentUser, setCurrentUser] = useState(null); // State for the currently selected user
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    title: "",
    organisation: "",
    country: "",
    subscriptionId: "", // Added for subscription selection
  }); // State to manage form data
  const [errors, setErrors] = useState({}); // State to manage form validation errors
  const [currentPage, setCurrentPage] = useState(1); // Current page for pagination
  const [totalPages, setTotalPages] = useState(1); // Total pages for pagination
  const [pageSize, setPageSize] = useState(5); // Number of users per page
  const location = useLocation();

  const userPermissions = useSelector((state) => state.getUser.user?.UserPermissions || []);



  const currentPermission = userPermissions.find(
    (perm) => `/pages/${perm.routeName}` === location.pathname
  );

  const hasAnyPermission = currentPermission?.UserPermissionsRelation?.canRead ||
  currentPermission?.UserPermissionsRelation?.canEdit ||
  currentPermission?.UserPermissionsRelation?.canAdd ||
  currentPermission?.UserPermissionsRelation?.canDelete;

  const handleClose = () => setShow(false);
  const handleShow = (user) => {
    setCurrentUser(user);
    setShow(true);
  };

  const handleEditClose = () => setEditShow(false);
  const handleEditShow = (user) => {
    setCurrentUser(user);
    setFormData(user); // Populate form with selected user details
    setEditShow(true);
  };

  const handleAddClose = () => setAddShow(false);
  const handleAddShow = () => {
    // Reset formData to empty values for adding a new user
    setFormData({
      username: "",
      email: "",
      title: "",
      organisation: "",
      country: "",
      subscriptionId: "", // Reset subscription
    });
    setAddShow(true);
  };

  const handleDeleteClose = () => setDeleteShow(false);
  const handleDeleteShow = (user) => {
    setCurrentUser(user);
    setDeleteShow(true);
  };

  // Fetch users when component mounts or when pagination changes
  useEffect(() => {
    fetchUsers();
    fetchSubscriptions(); // Fetch subscriptions when component mounts
  }, [currentPage, pageSize]);

  const fetchUsers = async () => {
    try {
      const token = sessionStorage.getItem("token"); // Retrieve token from session storage
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/getClientUsers`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add token to Authorization header
          },
          params: {
            page: currentPage,
            limit: pageSize,
          },
        }
      );
      setUsers(response.data.users);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };


  const fetchSubscriptions = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/getSubscriptions`); // Replace with your API endpoint
      setSubscriptions(response.data); // Assuming your API returns subscriptions in this format
    } catch (error) {
      console.error("Error fetching subscriptions:", error);
    }
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCountryChange = (country) => {
    setFormData({
      ...formData,
      country: country,
    });
  };

  const validateForm = () => {
    let formErrors = {};
    if (!formData.username) formErrors.username = "Name is required.";
    if (!formData.email) formErrors.email = "Email is required.";
    if (!formData.title) formErrors.title = "Title is required.";
    if (!formData.organisation) formErrors.organisation = "Organisation is required.";
    if (!formData.country) formErrors.country = "Country is required.";
    if (!formData.subscriptionId) formErrors.subscriptionId = "Subscription is required.";
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleAddUser = async () => {
    if (!validateForm()) return;


    try {
      const token = sessionStorage.getItem("token"); // Retrieve token from session storage
      await axios.post(`${process.env.REACT_APP_BASE_URL}/send-set-password-link`, formData,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add token to Authorization header
          },
        }
      ); // Replace with your API endpoint
      // fetchUsers();
       handleAddClose();
      toast.success("Password setup link has been send to your Email");
    } catch (error) {
      console.error("Error adding user:", error);
      toast.error("Failed to add user.");
    }
  };

  const handleEditUser = async () => {
    if (!validateForm()) return;

    try {
      const token = sessionStorage.getItem("token"); // Retrieve token from session storage
      await axios.put(
        `${process.env.REACT_APP_BASE_URL}/editUser/${currentUser.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add token to Authorization header
          },
        }
      );
      fetchUsers();
      handleEditClose();
      toast.success("User updated successfully!");
    } catch (error) {
      console.error("Error editing user:", error);
      toast.error("Failed to update user.");
    }
  };

  const handleDeleteUser = async () => {
    try {
      const token = sessionStorage.getItem("token"); // Retrieve token from session storage
      await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/deleteUser/${currentUser.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add token to Authorization header
          },
        }
      );
      fetchUsers();
      handleDeleteClose();
      toast.success("User deleted successfully!");
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error("Failed to delete user.");
    }
  };


  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

 

  return (
    <div>
      <Sidebar />
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/">PAGES</a>
            </li>
            <li className="breadcrumb-item active">Clients</li>
          </ul>
          <h1 className="page-header mb-0">Clients</h1>
        </div>
        {currentPermission?.UserPermissionsRelation?.canAdd &&
        <div className="ms-auto">
          <a href="#/" className="btn btn-outline-theme" onClick={handleAddShow}>
            <i className="fa fa-plus-circle fa-fw me-1"></i> ADD
          </a>
        </div>}
      </div>

      <Card>
        <div className="tab-content p-4">
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="border-top-0 pt-0 pb-2">Name</th>
                  <th className="border-top-0 pt-0 pb-2">Email</th>
                  <th className="border-top-0 pt-0 pb-2">Title</th>
                  <th className="border-top-0 pt-0 pb-2">Organisation</th>
                  <th className="border-top-0 pt-0 pb-2">Country</th>
                  <th className="border-top-0 pt-0 pb-2">Subscription</th> {/* New column for Subscription */}
                  {hasAnyPermission && (
                  <th className="border-top-0 pt-0 pb-2">Actions</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {users?.length > 0 ? (
                  users?.map((user) => (
                    <tr key={user.id}>
                      <td className="align-middle">{user.username || "N/A"}</td>
                      <td className="align-middle">{user.email || "N/A"}</td>
                      <td className="align-middle">{user.title || "N/A"}</td>
                      <td className="align-middle">{user.organisation || "N/A"}</td>
                      <td className="align-middle">{user.country || "N/A"}</td>
                      <td className="align-middle">
                        {user.Subscription ? user.Subscription.name : "N/A"}
                      </td>
                      <td>
                        <div className="action-btn-group d-flex">
                        
                        {

                        currentPermission?.UserPermissionsRelation?.canEdit &&
                          <button
                            className="btn btn-edit border border-warning text-warning px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center"
                            onClick={() => handleEditShow(user)}
                          >
                            Edit
                          </button>

                          }

                          {
                          currentPermission?.UserPermissionsRelation?.canRead &&
                          <button
                            className="btn btn-show border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center mx-2"
                            onClick={() => handleShow(user)}
                          >
                            Show
                          </button>
                          }
                          {
                          currentPermission?.UserPermissionsRelation?.canDelete &&
                          <button
                            className="btn btn-delete border border-danger text-danger px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center"
                            onClick={() => handleDeleteShow(user)}
                          >
                            Delete
                          </button>
                          }
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7" className="text-center">
                      No users available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* Pagination Controls */}
          {users?.length > 0 ?(   <div className="pagination-controls d-flex justify-content-center mt-4">
            <button
              className="btn btn-outline-secondary"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="mx-3">
              Page {currentPage} of {totalPages}
            </span>
            <button
              className="btn btn-outline-secondary"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>):(null)}
       
        </div>
      </Card>

      {/* Modals for Show, Add, Edit, and Delete Users */}
      <Modal show={show} onHide={handleClose} className="right-side-modal">
        <Modal.Header closeButton>
          <Modal.Title>Show User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group-area">
            <div className="form-group">
              <label><strong>Name:</strong></label>
              <p>{currentUser?.username || "N/A"}</p>
            </div>
            <div className="form-group">
              <label><strong>Email:</strong></label>
              <p>{currentUser?.email || "N/A"}</p>
            </div>
            <div className="form-group">
              <label><strong>Title:</strong></label>
              <p>{currentUser?.title || "N/A"}</p>
            </div>
            <div className="form-group">
              <label><strong>Organisation:</strong></label>
              <p>{currentUser?.organisation || "N/A"}</p>
            </div>
            <div className="form-group">
              <label><strong>Country:</strong></label>
              <p>{currentUser?.country || "N/A"}</p>
            </div>
            <div className="form-group">
              <label><strong>Subscription:</strong></label>
              <p>{currentUser?.Subscription?.name || "N/A"}</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={editShow} onHide={handleEditClose} className="right-side-modal">
        <Modal.Header closeButton>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group-area">
            <div className="form-group">
              <label>Name<span className="text-danger">*</span></label>
              <input
                name="username"
                className="form-control"
                value={formData.username}
                onChange={handleInputChange}
              />
              {errors.username && <span className="text-danger">{errors.username}</span>}
            </div>
            <div className="form-group">
              <label>Email<span className="text-danger">*</span></label>
              <input
                name="email"
                className="form-control"
                value={formData.email}
                onChange={handleInputChange}
              />
              {errors.email && <span className="text-danger">{errors.email}</span>}
            </div>
            <div className="form-group">
              <label>Title<span className="text-danger">*</span></label>
              <input
                name="title"
                className="form-control"
                value={formData.title}
                onChange={handleInputChange}
              />
              {errors.title && <span className="text-danger">{errors.title}</span>}
            </div>
            <div className="form-group">
              <label>Organisation<span className="text-danger">*</span></label>
              <input
                name="organisation"
                className="form-control"
                value={formData.organisation}
                onChange={handleInputChange}
              />
              {errors.organisation && <span className="text-danger">{errors.organisation}</span>}
            </div>
            <div className="form-group">
              <label>Country<span className="text-danger">*</span></label>
              <CountryDropdown
                value={formData.country}
                onChange={handleCountryChange}
                className="form-control country-dropdown subscription-select"
              />
              {errors.country && <span className="text-danger">{errors.country}</span>}
            </div>
            <div className="form-group">
              <label>Subscription<span className="text-danger">*</span></label>
              <select
                name="subscriptionId"
                className="form-control subscription-select"
                value={formData.subscriptionId}
                onChange={handleInputChange}
              >
                <option value="">Select Subscription</option>
                {subscriptions?.map((sub) => (
                  <option key={sub.id} value={sub.id}>
                    {sub.name}
                  </option>
                ))}
              </select>
              {errors.subscriptionId && <span className="text-danger">{errors.subscriptionId}</span>}
            </div>
            <div className="form-button">
              <button className="btn btn-outline-theme" onClick={handleEditUser}>
                Save Changes
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={addShow} onHide={handleAddClose} className="right-side-modal">
        <Modal.Header closeButton>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group-area">
            <div className="form-group">
              <label>Name<span className="text-danger">*</span></label>
              <input
                name="username"
                className="form-control"
                value={formData.username}
                onChange={handleInputChange}
              />
              {errors.username && <span className="text-danger">{errors.username}</span>}
            </div>
            <div className="form-group">
              <label>Email<span className="text-danger">*</span></label>
              <input
                name="email"
                className="form-control"
                value={formData.email}
                onChange={handleInputChange}
              />
              {errors.email && <span className="text-danger">{errors.email}</span>}
            </div>
            <div className="form-group">
              <label>Title<span className="text-danger">*</span></label>
              <input
                name="title"
                className="form-control"
                value={formData.title}
                onChange={handleInputChange}
              />
              {errors.title && <span className="text-danger">{errors.title}</span>}
            </div>
            <div className="form-group">
              <label>Organisation<span className="text-danger">*</span></label>
              <input
                name="organisation"
                className="form-control"
                value={formData.organisation}
                onChange={handleInputChange}
              />
              {errors.organisation && <span className="text-danger">{errors.organisation}</span>}
            </div>
            <div className="form-group">
              <label>Country<span className="text-danger">*</span></label>
              <CountryDropdown
                value={formData.country}
                onChange={handleCountryChange}
                className="form-control country-dropdown subscription-select"
              />
              {errors.country && <span className="text-danger">{errors.country}</span>}
            </div>
            <div className="form-group">
              <label>Subscription<span className="text-danger">*</span></label>
              <select
                name="subscriptionId"
                className="form-control subscription-select"
                value={formData.subscriptionId}
                onChange={handleInputChange}
              >
                <option value="">Select Subscription</option>
                {subscriptions?.map((sub) => (
                  <option key={sub.id} value={sub.id}>
                    {sub?.name}
                  </option>
                ))}
              </select>
              {errors.subscriptionId && <span className="text-danger">{errors.subscriptionId}</span>}
            </div>
            <div className="form-button">
              <button className="btn btn-outline-theme" onClick={handleAddUser}>
                ADD
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={deleteShow} onHide={handleDeleteClose} className="delete-modal">
        <Modal.Header closeButton>
          <Modal.Title>Delete User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this user?</p>
          <div className="delete-btn-group">
            <button className="btn dlt-btn dlt-btn-close" onClick={handleDeleteClose}>
              Cancel
            </button>
            <button className="btn dlt-btn" onClick={handleDeleteUser}>
              Delete
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Toast Container */}
      <ToastContainer />
    </div>
  );
}

export default ManageUsers;
