import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import Sidebar from "../../../components/sidebar/sidebar.jsx";
import "./BlurPage.css"; // Import the CSS file

function BlurPage() {
  return (
    <div className="blur-page">
   
      <div className="blur-overlay">
        {/* Use Link component to navigate to /pricing */}
        <Link to="/pages/pricing" className="premium-button">
          <i className="fa fa-crown me-2"></i> Get Premium Access
        </Link>
      </div>
      <div className="blurred-content">
        {/* Boxes will be arranged side by side using CSS */}
        <div className="info-box">
          <h2>Box 1 Heading</h2>
          <p>Sub-information for Box 1</p>
        </div>
        <div className="info-box">
          <h2>Box 2 Heading</h2>
          <p>Sub-information for Box 2</p>
        </div>
        <div className="info-box">
          <h2>Box 3 Heading</h2>
          <p>Sub-information for Box 3</p>
        </div>
        <div className="info-box">
          <h2>Box 4 Heading</h2>
          <p>Sub-information for Box 4</p>
        </div>
        <div className="info-box">
          <h2>Box 5 Heading</h2>
          <p>Sub-information for Box 5</p>
        </div>
        <div className="info-box">
          <h2>Box 6 Heading</h2>
          <p>Sub-information for Box 6</p>
        </div>
      </div>
    </div>
  );
}

export default BlurPage;
