import React, { useState, useEffect, useRef } from "react";

import "react-toastify/dist/ReactToastify.css";

import UserDataDesign from "./../../components/userData/iframebox";




function ArchiveReports() {
 
  return (

    <UserDataDesign
    tabType={"doc"}
    tableTitle={"Archive Reports"}

  />
  );
}

export default ArchiveReports;
