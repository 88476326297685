import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;

// Function to login user
export const loginUser = async (email, password,rememberMe) => {
  try {

    const lowercaseEmail = email?.toLowerCase();
    const response = await axios.post(`${baseURL}/login`, {
      email:lowercaseEmail,
      password,
      rememberMe

    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    // Return the response data
    return response.data;
  } catch (error) {
    // Throw the error to be handled by the calling function
    throw error.response ? error.response.data : new Error('An error occurred');
  }
};