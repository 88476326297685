import React, { useState } from "react";
import { Card } from "./../../components/card/card.jsx";
import "bootstrap/dist/css/bootstrap.min.css";
// import Modal from "react-bootstrap/Modal";

function PagesOrderDetails() {
  // const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  // const [editshows, setEditShows] = useState(false);
  // const handleCloses = () => setEditShows(false);
  // const handleShows = () => setEditShows(true);

//   const [addShow, setAddShow] = useState(false);
//   const handleAddClose = () => setAddShow(false);
//   const handleAddShow = () => setAddShow(true);

  return (
    <div>
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/">PAGES</a>
            </li>
            <li className="breadcrumb-item active">MENU PERMISSION</li>
          </ul>
          <h1 className="page-header mb-0">Menu Permission</h1>
        </div>

        {/* <div className="ms-auto">
          <a
            href="#/"
            className="btn btn-outline-theme"
            onClick={handleAddShow}
          >
            <i className="fa fa-plus-circle fa-fw me-1"></i> ADD
          </a>
        </div> */}
      </div>

      <div className="mb-md-4 mb-3 d-md-flex">
        <div className="mt-md-0 mt-2">
          <a
            href="#/"
            className="text-inverse text-opacity-75 text-decoration-none"
          >
            <i className="fa fa-download fa-fw me-1 text-theme"></i> Export
          </a>
        </div>
        <div className="ms-md-4 mt-md-0 mt-2 dropdown-toggle">
          <a
            href="#/"
            data-bs-toggle="dropdown"
            className="text-inverse text-opacity-75 text-decoration-none"
          >
            More Actions
          </a>
          <div className="dropdown-menu">
            <a className="dropdown-item" href="#/">
              Action
            </a>
            <a className="dropdown-item" href="#/">
              Another action
            </a>
            <a className="dropdown-item" href="#/">
              Something else here
            </a>
            <div role="separator" className="dropdown-divider"></div>
            <a className="dropdown-item" href="#/">
              Separated link
            </a>
          </div>
        </div>
      </div>

      <Card>
        <div className="tab-content p-4">
          <div className="table-responsive">
            <table className="table table-hover text-nowrap">
              <thead>
                <tr>
                  <th className="border-top-0 pt-0 pb-2">Menus</th>
                  <th className="border-top-0 pt-0 pb-2">$200</th>
                  <th className="border-top-0 pt-0 pb-2">$500</th>
                  <th className="border-top-0 pt-0 pb-2">Free</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="align-middle border-bottom-0">Lorem</td>
                  <td className="align-middle border-bottom-0">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="product1"
                      />
                      <label class="form-check-label" for="product1"></label>
                    </div>
                  </td>
                  <td className="align-middle border-bottom-0">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="product2"
                      />
                      <label class="form-check-label" for="product2"></label>
                    </div>
                  </td>
                  <td className="align-middle border-bottom-0">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="product3"
                      />
                      <label class="form-check-label" for="product3"></label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="align-middle ps-5 border-0 fs-12px">Ispum</td>
                  <td className="align-middle border-0">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="product1"
                      />
                      <label class="form-check-label" for="product1"></label>
                    </div>
                  </td>
                  <td className="align-middle border-0">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="product2"
                      />
                      <label class="form-check-label" for="product2"></label>
                    </div>
                  </td>
                  <td className="align-middle border-0">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="product3"
                      />
                      <label class="form-check-label" for="product3"></label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="align-middle ps-5 border-0 fs-12px">Ispum</td>
                  <td className="align-middle border-0">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="product1"
                      />
                      <label class="form-check-label" for="product1"></label>
                    </div>
                  </td>
                  <td className="align-middle border-0">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="product2"
                      />
                      <label class="form-check-label" for="product2"></label>
                    </div>
                  </td>
                  <td className="align-middle border-0">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="product3"
                      />
                      <label class="form-check-label" for="product3"></label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="align-middle ps-5 border-top-0 fs-12px">
                    Ispum
                  </td>
                  <td className="align-middle border-top-0">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="product1"
                      />
                      <label class="form-check-label" for="product1"></label>
                    </div>
                  </td>
                  <td className="align-middle border-top-0">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="product2"
                      />
                      <label class="form-check-label" for="product2"></label>
                    </div>
                  </td>
                  <td className="align-middle border-top-0">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="product3"
                      />
                      <label class="form-check-label" for="product3"></label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="align-middle border-bottom-0">Lorem</td>
                  <td className="align-middle border-bottom-0">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="product1"
                      />
                      <label class="form-check-label" for="product1"></label>
                    </div>
                  </td>
                  <td className="align-middle border-bottom-0">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="product2"
                      />
                      <label class="form-check-label" for="product2"></label>
                    </div>
                  </td>
                  <td className="align-middle border-bottom-0">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="product3"
                      />
                      <label class="form-check-label" for="product3"></label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="align-middle ps-5 border-0 fs-12px">Ispum</td>
                  <td className="align-middle border-0">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="product1"
                      />
                      <label class="form-check-label" for="product1"></label>
                    </div>
                  </td>
                  <td className="align-middle border-0">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="product2"
                      />
                      <label class="form-check-label" for="product2"></label>
                    </div>
                  </td>
                  <td className="align-middle border-0">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="product3"
                      />
                      <label class="form-check-label" for="product3"></label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="align-middle ps-5 border-0 fs-12px">Ispum</td>
                  <td className="align-middle border-0">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="product1"
                      />
                      <label class="form-check-label" for="product1"></label>
                    </div>
                  </td>
                  <td className="align-middle border-0">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="product2"
                      />
                      <label class="form-check-label" for="product2"></label>
                    </div>
                  </td>
                  <td className="align-middle border-0">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="product3"
                      />
                      <label class="form-check-label" for="product3"></label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="align-middle ps-5 border-top-0 fs-12px">
                    Ispum
                  </td>
                  <td className="align-middle border-top-0">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="product1"
                      />
                      <label class="form-check-label" for="product1"></label>
                    </div>
                  </td>
                  <td className="align-middle border-top-0">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="product2"
                      />
                      <label class="form-check-label" for="product2"></label>
                    </div>
                  </td>
                  <td className="align-middle border-top-0">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="product3"
                      />
                      <label class="form-check-label" for="product3"></label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="align-middle border-bottom-0">Lorem</td>
                  <td className="align-middle border-bottom-0">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="product1"
                      />
                      <label class="form-check-label" for="product1"></label>
                    </div>
                  </td>
                  <td className="align-middle border-bottom-0">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="product2"
                      />
                      <label class="form-check-label" for="product2"></label>
                    </div>
                  </td>
                  <td className="align-middle border-bottom-0">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="product3"
                      />
                      <label class="form-check-label" for="product3"></label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="align-middle ps-5 border-0 fs-12px">Ispum</td>
                  <td className="align-middle border-0">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="product1"
                      />
                      <label class="form-check-label" for="product1"></label>
                    </div>
                  </td>
                  <td className="align-middle border-0">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="product2"
                      />
                      <label class="form-check-label" for="product2"></label>
                    </div>
                  </td>
                  <td className="align-middle border-0">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="product3"
                      />
                      <label class="form-check-label" for="product3"></label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="align-middle ps-5 border-0 fs-12px">Ispum</td>
                  <td className="align-middle border-0">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="product1"
                      />
                      <label class="form-check-label" for="product1"></label>
                    </div>
                  </td>
                  <td className="align-middle border-0">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="product2"
                      />
                      <label class="form-check-label" for="product2"></label>
                    </div>
                  </td>
                  <td className="align-middle border-0">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="product3"
                      />
                      <label class="form-check-label" for="product3"></label>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="align-middle ps-5 border-top-0 fs-12px">
                    Ispum
                  </td>
                  <td className="align-middle border-top-0">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="product1"
                      />
                      <label class="form-check-label" for="product1"></label>
                    </div>
                  </td>
                  <td className="align-middle border-top-0">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="product2"
                      />
                      <label class="form-check-label" for="product2"></label>
                    </div>
                  </td>
                  <td className="align-middle border-top-0">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="product3"
                      />
                      <label class="form-check-label" for="product3"></label>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="d-md-flex align-items-center">
            <div className="me-md-auto text-md-left text-center mb-2 mb-md-0">
              Showing 1 to 10 of 57 entries
            </div>
            <ul className="pagination mb-0 justify-content-center">
              <li className="page-item disabled">
                <a className="page-link" href="#/">
                  Previous
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#/">
                  1
                </a>
              </li>
              <li className="page-item active">
                <a className="page-link" href="#/">
                  2
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#/">
                  3
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#/">
                  4
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#/">
                  5
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#/">
                  6
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#/">
                  Next
                </a>
              </li>
            </ul>
          </div>
        </div>
      </Card>

      {/* <Modal show={show} onHide={handleClose} className="right-side-modal">
		  <Modal.Header closeButton>
			<Modal.Title>Show Modal</Modal.Title>
		  </Modal.Header>
		  <Modal.Body>Woohoo, you are reading this text in a modal!</Modal.Body>
		  <Modal.Footer></Modal.Footer>
		</Modal>
  
		<Modal
		  show={editshows}
		  onHide={handleCloses}
		  className="right-side-modal"
		>
		  <Modal.Header closeButton>
			<Modal.Title>Edit Modal</Modal.Title>
		  </Modal.Header>
		  <Modal.Body>Woohoo, you are reading this text in a modal!</Modal.Body>
		  <Modal.Footer></Modal.Footer>
		</Modal> */}

      {/* <Modal
        show={addShow}
        onHide={handleAddClose}
        className="right-side-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="form-group-area">
            <div className="form-group">
              <lable>Menu Name</lable>
              <input className="form-control" />
            </div>
            <div className="form-group">
              <lable>Sub-Menu Name</lable>
              <input className="form-control" />
            </div>
            <div className="form-group">
              <lable>Subcription plan</lable>
            </div>
            <div className="subs-plan-group">
              <div className="form-group d-flex">
                <lable>$ 200</lable>
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="product3"
                  />
                  <label class="form-check-label" for="product3"></label>
                </div>
              </div>
              <div className="form-group d-flex">
                <lable>$ 500</lable>
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="product3"
                  />
                  <label class="form-check-label" for="product3"></label>
                </div>
              </div>
              <div className="form-group d-flex">
                <lable>Free</lable>
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="product3"
                  />
                  <label class="form-check-label" for="product3"></label>
                </div>
              </div>
            </div>

            <div className="form-button mt-3">
              <button className="btn btn-save">Add</button>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}
    </div>
  );
}

export default PagesOrderDetails;
