// slices/setSectionNameSlice.js
import { createSlice } from '@reduxjs/toolkit';

const setSectionNameSlice = createSlice({
  name: 'example',
  initialState: { sectionName:"" },
  reducers: {
     setSectionName: (state, action) => {
        state.sectionName = action.payload;
      },
    
   
  },
});

// Export actions and reducer
export const { setSectionName } = setSectionNameSlice.actions;
export default setSectionNameSlice.reducer;
