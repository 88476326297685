import React, { useEffect, useState } from "react";
import { Card } from "./../../components/card/card.jsx";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios"; // Import axios for API calls

function MenuPermissions() {
  const [permissions, setPermissions] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [checkedState, setCheckedState] = useState({}); // To manage checkbox states

  // Fetch permissions, subscriptions, and subscription permissions when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch permissions
        const permissionsResponse = await axios.get(`${process.env.REACT_APP_BASE_URL}/getAllPermissions`);
        const permissionsData = permissionsResponse.data;
        setPermissions(permissionsData);

        // Fetch subscriptions
        const subscriptionsResponse = await axios.get(`${process.env.REACT_APP_BASE_URL}/getSubscriptions`);
        const subscriptionsData = subscriptionsResponse.data;
        setSubscriptions(subscriptionsData);

        // Fetch all subscription permissions
        const subscriptionPermissionsResponse = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/getAllSubPermissions`, 
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}` // Retrieve token from session storage
              }
            }
          );
        const subscriptionPermissionsData = subscriptionPermissionsResponse.data;

        // Initialize the checkbox state based on fetched data
        const initialCheckedState = {};
        permissionsData.forEach((permission) => {
          subscriptionsData.forEach((subscription) => {
            // Check if there is an existing subscription permission and set initial checkbox state accordingly
            const isChecked = subscriptionPermissionsData.some(
              (subPermission) =>
                subPermission.permissionId === permission.id &&
                subPermission.subscriptionId === subscription.id &&
                subPermission.status === true
            );
            initialCheckedState[`${permission.id}_${subscription.id}`] = isChecked;
          });
        });

        setCheckedState(initialCheckedState);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, []);

  // Handle checkbox change
  const handleCheckboxChange = async (permissionId, subscriptionId) => {
    const isParentPermission = permissions.find((p) => p.id === permissionId)?.parentId === null;

    setCheckedState((prevState) => {
      const newState = { ...prevState };
      const currentChecked = prevState[`${permissionId}_${subscriptionId}`];

      // If it's a parent permission, toggle all its child permissions
      if (isParentPermission) {
        permissions
          .filter((permission) => permission.parentId === permissionId || permission.id === permissionId) // Include parent itself
          .forEach((permission) => {
            const newCheckedState = !currentChecked;
            newState[`${permission.id}_${subscriptionId}`] = newCheckedState;

            // Call API for each affected permission
            updateSubscriptionPermission(permission.id, subscriptionId, newCheckedState);
          });
      } else {
        // Toggle only the specific child checkbox
        const newCheckedState = !currentChecked;
        newState[`${permissionId}_${subscriptionId}`] = newCheckedState;

        // Call API for child permission
        updateSubscriptionPermission(permissionId, subscriptionId, newCheckedState);

        // Automatically check or uncheck the parent based on the child states
        const parentPermission = permissions.find((p) => p.id === permissionId)?.parentId;
        if (parentPermission) {
          const siblings = permissions.filter((p) => p.parentId === parentPermission);

          // Check if any child is checked
          const anyChildChecked = siblings.some((child) => newState[`${child.id}_${subscriptionId}`]);

          // Update the parent checkbox based on child states
          newState[`${parentPermission}_${subscriptionId}`] = anyChildChecked;

          // Call API for parent permission based on new state
          updateSubscriptionPermission(parentPermission, subscriptionId, anyChildChecked);
        }
      }

      return newState;
    });
  };

  // Function to call the API and update subscription permission
  const updateSubscriptionPermission = async (permissionId, subscriptionId, status) => {
    try {
      const token = sessionStorage.getItem("token"); // Retrieve token from session storage
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/assignSubscriptionPermisiion`,
        {
          permissionId,
          subscriptionId,
          status, // Include status in the API call payload
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add token to Authorization header
          },
        }
      );
    } catch (error) {
      console.error("Error updating subscription permission:", error);
    }
  };
  

  return (
    <div>
      {/* Your existing header and UI structure here */}
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/">PAGES</a>
            </li>
            <li className="breadcrumb-item active">User Permissions</li>
          </ul>
          <h1 className="page-header mb-0">Menu Permission</h1>
        </div>

       
      </div>

      <Card>
        <div className="tab-content p-4">
          <div className="table-responsive">
            <table className="table table-hover text-nowrap">
              <thead>
                <tr>
                  <th className="border-top-0 pt-0 pb-2">Menus</th>
                  {subscriptions.map((subscription) => (
                    <th key={subscription.id} className="border-top-0 pt-0 pb-2">
                      {subscription.name}
                      {subscription.price > 0 && ` $${Math.round(subscription.price)}`} {/* Show price only if it's non-zero */}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {permissions.map((permission) => (
                  <tr key={permission.id}>
                    <td
                      className={`align-middle border-bottom-0 ${
                        permission?.parentId ? "fs-12px" : ""
                      }`}
                      style={{
                        fontWeight: permission?.parentId ? "normal" : "bolder",
                        color: permission?.parentId ? "#bfb2b2" : "", // Lighter color for items with a parent
                      }}
                    >
                      {permission.name}
                    </td>
                    {subscriptions.map((subscription) => (
                      <td key={`${permission.id}_${subscription.id}`} className="align-middle border-bottom-0">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={`checkbox_${permission.id}_${subscription.id}`}
                            checked={checkedState[`${permission.id}_${subscription.id}`] || false}
                            onChange={() => handleCheckboxChange(permission.id, subscription.id)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`checkbox_${permission.id}_${subscription.id}`}
                          ></label>
                        </div>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default MenuPermissions;
