import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { Card, CardBody } from "./../../components/card/card.jsx";
import { AiOutlineFileSearch } from "react-icons/ai";
import { Modal, Spinner } from "react-bootstrap";
import UserTable from "./table.js";
import EmbeddedDoc from "./getDocs.js"; // Import EmbeddedDoc

let zIndexCounter = 1000; // Global z-index counter

function UserDataDesign({ tabType, tableTitle }) {
  const [documents, setDocuments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [mainCategoryId, setMainCategoryId] = useState(null);
  const [subCategoryId, setSubCategoryId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalIframeSrc, setModalIframeSrc] = useState("");
  const [activeTab, setActiveTab] = useState(tabType);
  const [draggedCardIndex, setDraggedCardIndex] = useState(null);
  const [cardZIndices, setCardZIndices] = useState({});
  const [cardSizes, setCardSizes] = useState({}); // Store width and height per card
  const columns = ["Title", "Created By", "Tags", "Description"];
  const [isDragging, setIsDragging] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState(null); // Add state for the selected document

  const sizeMap = {
    small: { width: "300px", height: "300px" }, // Adjust size as needed
    medium: { width: "500px", height: "500px" }, // Adjust size as needed
    large: { width: "1000px", height: "1000px" }, // Adjust size as needed
  };

  const userPermissions = useSelector(
    (state) => state.getUser.user?.Subscription?.Permissions
  );

  const sectionInfo = useSelector(
    (state) => state.sectionName.sectionName || []
  );

  const token = sessionStorage.getItem("token");

  const containerRef = useRef(null);
  const observerRef = useRef();

  const toggleBookmark = async (index) => {
    const document = documents[index]; // Get the selected document
    const { id: documentId } = document; // Assuming document has an 'id' field

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/bookmarkDocument`,
        { documentId },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // Update the state to reflect the saved status
      const updatedDocuments = [...documents];
      updatedDocuments[index] = {
        ...document,
        saved: response.data.saved, // Update the saved status from the API response
      };

      setDocuments(updatedDocuments); // Update the documents state
    } catch (error) {
      console.error("Error bookmarking document:", error);
    }
  };

  const findCategoryIds = () => {
    const mainCategory = userPermissions.find(
      (permission) =>
        permission.name.includes(sectionInfo?.parentName) &&
        permission.parentId === null
    );

    if (mainCategory) {
      setMainCategoryId(mainCategory.id);

      const subCategory = userPermissions.find(
        (permission) =>
          permission.name.includes(sectionInfo?.childName) &&
          permission.parentId === mainCategory.id
      );

      if (subCategory) {
        setSubCategoryId(subCategory.id);
      }
    }
  };

  useEffect(() => {
    findCategoryIds();
  }, []);

  useEffect(() => {
    if (mainCategoryId) {
      fetchDocuments(currentPage);
      fetchTitle();
    }
  }, [mainCategoryId, subCategoryId, currentPage, token]);

  const fetchDocuments = (page) => {
    setLoading(true); // Show loading spinner
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/getDocInfobyId`, {
        params: {
          mainCategoryId,
          subCategoryId,
          pageSize: 10,
          page,
        },
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const fetchedDocuments = response.data.documents.map((doc) => {
          // Determine card size based on embedWindowSize field
          const size = sizeMap[doc.embedWindowSize] || sizeMap.small; // Fallback to small size
          return {
            ...doc,
            size, // Attach the determined size to each document
          };
        });
        setDocuments((prevDocs) => [...prevDocs, ...fetchedDocuments]);
        setTotalPages(response.data.totalPages);
      })
      .catch(() => {
        toast.error("No Documents Found");
      })
      .finally(() => {
        setLoading(false); // Hide loading spinner
      });
  };

  const fetchTitle = () => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/titlesbyCategory`,
        {
          mainCategoryId,
          subCategoryId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        console.log("title", response.data);
        setTitle(response?.data[0]?.title);
        // setTotalPages(response.data.totalPages);
      })
      .catch(() => {
        toast.error("No Title Found");
      });
  };

  const lastDocumentElementRef = (node) => {
    if (loading) return;
    if (observerRef.current) observerRef.current.disconnect();
    observerRef.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && currentPage < totalPages) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    });
    if (node) observerRef.current.observe(node);
  };

  const handleFullscreenClick = (doc) => {
    setModalIframeSrc(doc?.docs[0]);
    setModalTitle(doc.title);
    setShowModal(true);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleBack = () => {
    setSelectedDoc(null); // Clear selectedDoc to show the table again
  };

  const handleRowClick = (doc) => {
    setSelectedDoc(doc); // Set the clicked document as selected
  };

  const handleDragStart = (e, index) => {
    if (e.target.className.includes("resize-handle")) return;
  
    e.preventDefault();
    e.stopPropagation();
  
    setDraggedCardIndex(index);
    setIsDragging(true); // Set dragging to true
  
    const cardElement = e.currentTarget;
    const iframe = cardElement.querySelector("iframe");
    iframe.style.pointerEvents = "none";

    const currentLeft = parseFloat(cardElement.style.left) || 0;
    const currentTop = parseFloat(cardElement.style.top) || 0;
  
    const offsetX = e.clientX - currentLeft;
    const offsetY = e.clientY - currentTop;
  
    cardElement.style.cursor = "grabbing";
  
    setCardZIndices((prev) => ({
      ...prev,
      [index]: ++zIndexCounter,
    }));
  
    const handleMouseMove = (moveEvent) => {
      const newLeft = moveEvent.clientX - offsetX;
      const newTop = moveEvent.clientY - offsetY;
  
      cardElement.style.position = "relative";
      cardElement.style.left = `${newLeft}px`;
      cardElement.style.top = `${newTop}px`;
    };
  
    const handleMouseUp = () => {
      iframe.style.pointerEvents = "auto";
      setDraggedCardIndex(null);
      setIsDragging(false); // Reset dragging state
      cardElement.style.cursor = "grab";
  
      // Clean up event listeners
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
  };
  

  // Attach drag event listener to each card in your render function

  // Attach drag event listener to each card in your render function

  const handleResize = (e, index, direction) => {
    e.preventDefault();
  
    const cardElement = e.currentTarget.parentElement;
    const initialWidth = cardElement.offsetWidth;
    const initialHeight = cardElement.offsetHeight;
    const startX = e.clientX;
    const startY = e.clientY;
  
    const handleMouseMove = (moveEvent) => {
      const deltaX = moveEvent.clientX - startX;
      const deltaY = moveEvent.clientY - startY;
  
      // Dynamically adjust width and height
      let newWidth = initialWidth + (direction.includes("right") ? deltaX : -deltaX);
      let newHeight = initialHeight + (direction.includes("bottom") ? deltaY : -deltaY);
  
      // Minimum width and height constraints
      newWidth = Math.max(300, newWidth);
      newHeight = Math.max(300, newHeight);
  
      // Dynamic maximum width and height constraints
      const containerBounds = containerRef.current.getBoundingClientRect();
      const maxWidth = Math.max(containerBounds.width, newWidth); // Allow it to go beyond the container width if needed
      const maxHeight = Math.max(containerBounds.height, newHeight);
  
      // Constrain the new width and height to the maximum allowed
      newWidth = Math.min(newWidth, maxWidth);
      newHeight = Math.min(newHeight, maxHeight);
  
      setCardSizes((prev) => ({
        ...prev,
        [index]: { width: newWidth, height: newHeight },
      }));
    };
  
    const handleMouseUp = () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
  };
  

  return (
    <div
      ref={containerRef}
      style={{
        position: "relative",
        // minHeight: "100vh",
        // overflow: "auto", // Allow scrolling if content exceeds the viewport
      }}
    >
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <Spinner animation="border" variant="primary" /> {/* Loader */}
        </div>
      ) : (
        <>
          {/* <div className="tab-bar" style={{ marginBottom: "20px" }}>
        <button
          className={`btn btn-outline-theme ${activeTab === "embedded" ? "active" : ""}`}
          onClick={() => setActiveTab("embedded")}
          style={{ marginRight: "20px" }}
        >
          Embedded Data
        </button>
        <button
          className={`btn btn-outline-theme ${activeTab === "documents" ? "active" : ""}`}
          onClick={() => setActiveTab("documents")}
        >
          Documents
        </button>
      </div> */}

          {title ? (
            <div className="col-xl-12 mt-sm-0 mt-2">
              {" "}
              {/* Full-width card */}
              <Card className="mb-3" style={{ width: "100%" }}>
                <CardBody
                className="p-sm-3 p-1"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  {/* Set a fixed height and use flexbox for centering */}
                  <div className="d-flex align-items-center">
                    {" "}
                    {/* Ensure vertical alignment of icon and text */}
                    <AiOutlineFileSearch
                      size={24}
                      style={{ marginRight: "10px", color: "#6c757d" }}
                    />
                    <span
                      className="text-center"
                      style={{ textAlign: "center" }}
                    >
                      {title}
                    </span>
                  </div>
                </CardBody>
              </Card>
            </div>
          ) : null}

          {activeTab == "embedded" ? (
            <div className="row" style={{ position: "relative", minWidth: '100%', overflow: 'auto' }}>
              {documents.length > 0 ? (
                documents.map((doc, index) => (
                  <div
                    className={`mb-4 ${
                      doc.size.width === "1000px"
                        ? "col-md-12"
                        : "col-lg-6 col-md-12"
                    }`}
                    key={index}
                    onMouseDown={(e) => handleDragStart(e, index)}
                    style={{ position: "relative" }}
                  >
                    <Card 
                      className="mb-3"
                      style={{
                        position: "relative",
                        zIndex: cardZIndices[index] || 1,
                        width: cardSizes[index]?.width || doc?.size?.width, // Default width
                        height: cardSizes[index]?.height || doc?.size?.height, // Default height
                        border:
                          isDragging && draggedCardIndex === index
                            ? "2px solid blue"
                            : "2px solid transparent",
                      }}
                    >
                      <CardBody>
                        <div className="d-flex fw-bold small mb-3">
                          <span className="flex-grow-1">{doc?.title}</span>

                          <i
                            className={
                              doc.saved ? "fas fa-bookmark" : "far fa-bookmark"
                            }
                            style={{
                              cursor: "pointer",
                              marginRight: "10px",
                              marginTop: "3px",
                            }}
                            onClick={() => toggleBookmark(index)}
                          ></i>
                          <i
                            className="bi bi-fullscreen"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleFullscreenClick(doc)}
                          ></i>
                        </div>
                        <iframe
                          src={doc?.docs[0]}
                          width="100%"
                          height="90%" // Set height to 90% of the card body
                          style={{
                            // border: "1px solid #ccc",
                            borderRadius: "4px",
                            // transition: "height 0.2s ease", // Smooth transition for height changes
                            // display: "block",
                          }}
                        ></iframe>

                        {/* Resize Handles */}
                        <div
                          className="resize-handle"
                          onMouseDown={(e) =>
                            handleResize(e, index, "top-left")
                          }
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            cursor: "nwse-resize",
                            width: "10px",
                            height: "10px",
                            backgroundColor: "transparent",
                          }}
                        ></div>
                        <div
                          className="resize-handle"
                          onMouseDown={(e) =>
                            handleResize(e, index, "top-right")
                          }
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            cursor: "nesw-resize",
                            width: "10px",
                            height: "10px",
                            backgroundColor: "transparent",
                          }}
                        ></div>
                        <div
                          className="resize-handle"
                          onMouseDown={(e) =>
                            handleResize(e, index, "bottom-left")
                          }
                          style={{
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            cursor: "nesw-resize",
                            width: "10px",
                            height: "10px",
                            backgroundColor: "transparent",
                          }}
                        ></div>
                        <div
                          className="resize-handle"
                          onMouseDown={(e) =>
                            handleResize(e, index, "bottom-right")
                          }
                          style={{
                            position: "absolute",
                            bottom: 0,
                            right: 0,
                            cursor: "nwse-resize",
                            width: "10px",
                            height: "10px",
                            backgroundColor: "transparent",
                          }}
                        ></div>
                      </CardBody>
                    </Card>
                  </div>
                ))
              ) : (
                <div
                  className="d-flex flex-column justify-content-center align-items-center"
                  style={{ minHeight: "100vh", textAlign: "center" }}
                >
                  <AiOutlineFileSearch size={50} color="#ccc" />
                  <h2>No Documents Found</h2>
                </div>
              )}
              <div ref={lastDocumentElementRef} />
            </div>
          ) : (
            <div>
              {/* Conditionally render the UserTable if no document is selected */}
              {!selectedDoc && (
                <UserTable
                  data={documents}
                  columns={columns}
                  pageHeading={tableTitle}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  handlePageChange={handlePageChange}
                  handleRowClick={handleRowClick} // Pass the row click handler to the UserTable
                />
              )}

              {/* Conditionally render the EmbeddedDoc component if a document is selected */}
              {selectedDoc && (
                <div style={{ marginTop: "20px" }}>
                  <EmbeddedDoc
                    selectedDoc={selectedDoc}
                    handleBack={handleBack}
                  />{" "}
                  {/* Pass the selected document and back handler */}
                </div>
              )}

              <ToastContainer />
            </div>
          )}

          <Modal
            show={showModal}
            onHide={handleCloseModal}
            centered
            className="custom-fullscreen-modal"
            style={{ padding: 0, margin: 0 }} // No extra spacing
          >
            <Modal.Body
              style={{
                padding: 0,
                margin: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh", // Full screen height
                width: "820px", // Ensure full width
                overflow: "hidden", // Prevent unnecessary scrollbars
                position: "relative", // For the absolute positioning of the close button
              }}
            >
              {/* Iframe to cover the entire modal body */}
              <iframe
                src={modalIframeSrc}
                title="Fullscreen Document"
                style={{
                  border: "none",
                  width: "100%", // Full width of modal body
                  height: "100%", // Full height of modal body
                }}
              ></iframe>
            </Modal.Body>
          </Modal>
        </>
      )}

      <ToastContainer />
    </div>
  );
}

export default UserDataDesign;
