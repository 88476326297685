import React from "react";
import { Card } from "./../../components/card/card.jsx";
import "bootstrap/dist/css/bootstrap.min.css";

function UserTable({
  data,
  columns,
  pageHeading,
  handleAddShow,
  handleEditShow,
  handleDeleteShow,
  handleDocsShow,
  currentPage,
  totalPages,
  handlePageChange,
  handleRowClick, // Accept handleRowClick as a prop
}) {
  return (
    <div>
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/">PAGES</a>
            </li>
            <li className="breadcrumb-item active">{pageHeading}</li>
          </ul>
          <h1 className="page-header mb-0">{pageHeading}</h1>
        </div>
      </div>

      <Card>
        <div className="tab-content p-4">
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
             
                <th className="border-top-0 pt-0 pb-2">
                      Title
                    </th>
                    <th className="border-top-0 pt-0 pb-2">
                    Tags
                    </th>
                    <th  className="border-top-0 pt-0 pb-2">
                    Description
                    </th>
                </tr>
              </thead>
              <tbody>
                {data?.map((doc, index) => (
                  <tr
                    key={index}
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRowClick(doc)} // Call handleRowClick on row click
                  >
                    <td className="align-middle">{doc?.title}</td>
                 
                    <td className="align-middle">
                      {doc?.tags &&
                        doc?.tags.map((tag, idx) => (
                          <span
                            key={idx}
                            className="badge me-1"
                            style={{
                              backgroundColor: "#6c757d",
                              color: "#fff",
                              border: "1px solid #fff",
                            }}
                          >
                            #{tag}
                          </span>
                        ))}
                    </td>
                    <td className="align-middle">{doc?.description || "N/A"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="pagination-controls d-flex justify-content-center mt-4">
            <button
              className="btn btn-outline-secondary"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="mx-3">
              Page {currentPage} of {totalPages}
            </span>
            <button
              className="btn btn-outline-secondary"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default UserTable;
