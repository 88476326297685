import React, { useEffect, useContext, useState } from 'react';
import { AppSettings } from './../../config/app-settings.js';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function PagesContactUs() {
  const context = useContext(AppSettings);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    context.setAppHeaderNone(true);
    context.setAppSidebarNone(true);
    context.setAppContentClass('p-0');
    
    return function cleanUp() {
      context.setAppHeaderNone(false);
      context.setAppSidebarNone(false);
      context.setAppContentClass('');
    };
    
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = { name, email, message };
    
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/contactUs`, payload);
      if (response.status === 200) {
        toast.success('Message sent successfully!');
        setName('');
        setEmail('');
        setMessage('');
      } else {
        toast.error('Failed to send message. Please try again.');
      }
    } catch (error) {
      toast.error('An error occurred. Please try again.');
      console.error('Error sending message:', error);
    }
  };

  return (
    <div className="contact-us d-flex align-items-center justify-content-center min-vh-100">
      <ToastContainer />
      <div className="contact-us-content text-center">
        <h2 className="mb-4">Contact Us</h2>
        <p className="mb-4">We'd love to hear from you! Please fill out the form below to get in touch.</p>
        <form  className="text-start">
          <div className="mb-3">
            <input 
              type="text" 
              className="form-control" 
              placeholder="Your Name" 
              value={name}
              onChange={(e) => setName(e.target.value)}
              required 
            />
          </div>
          <div className="mb-3">
            <input 
              type="email" 
              className="form-control" 
              placeholder="Your Email" 
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required 
            />
          </div>
          <div className="mb-4">
            <textarea 
              className="form-control" 
              placeholder="Your Message" 
              rows="4" 
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required 
            />
          </div>
          <button type="submit" className="btn btn-outline-theme btn-lg w-100">SEND MESSAGE</button>
        </form>
        <div className="text-center small text-inverse text-opacity-50 mt-4">
          &copy; 2024 YourCompany. All Rights Reserved.
        </div>
      </div>
    </div>
  );
}

export default PagesContactUs;
