import React, { useState, useEffect, useRef } from "react";

import "react-toastify/dist/ReactToastify.css";

import UserDataDesign from "./../../components/userData/iframebox.js";




function Universities() {
 
  return (

    <UserDataDesign
    tabType={"embedded"}

  />
  );
}

export default Universities;
