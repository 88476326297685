import React, { useState, useEffect } from "react";
import { Card } from "../../components/card/card.jsx";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import Sidebar from "../../components/sidebar/sidebar.jsx";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CountryDropdown } from "react-country-region-selector";
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";

function ManageSubAdmins() {
  const [show, setShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [addShow, setAddShow] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [deleteShow, setDeleteShow] = useState(false);
  const [users, setUsers] = useState([]); // State to store users
  const [currentUser, setCurrentUser] = useState(null); // State for the currently selected user
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    title: "",
    organisation: "",
    country: "",
    permissionSettings: {},
  }); // State to manage form data
  const [errors, setErrors] = useState({}); // State to manage form validation errors
  const [currentPage, setCurrentPage] = useState(1); // Current page for pagination
  const [totalPages, setTotalPages] = useState(1); // Total pages for pagination
  const [pageSize, setPageSize] = useState(5); // Number of users per page
  const location = useLocation();

  const handleClose = () => setShow(false);
  const handleShow = (user) => {
    setCurrentUser(user);
    setShow(true);
  };

  const handleEditClose = () => setEditShow(false);
//   const handleEditShow = (user) => {
//     setCurrentUser(user);
//     setFormData(user); // Populate form with selected user details
//     setSelectedPermissions([{value:1,label:"Dashboard"}])
//     setEditShow(true);
//     // setSelectedPermissions([]);
//   };

  const handleEditShow = (user) => {
    setCurrentUser(user);
    setFormData({
      username: user.username,
      email: user.email,
      title: user.title,
      organisation: user.organisation,
      country: user.country,
      permissionSettings: user?.UserPermissions?.reduce((acc, perm) => {
        acc[perm.id] = {
          read: perm.UserPermissionsRelation.canRead,
          update: perm.UserPermissionsRelation.canEdit,
          delete: perm.UserPermissionsRelation.canDelete,
          add: perm.UserPermissionsRelation.canAdd,
        };
        return acc;
      }, {}),
    });
    setSelectedPermissions(user?.UserPermissions?.map(perm => ({ value: perm.id, label: perm.name })));
    setEditShow(true);
  };
  

  const handleAddClose = () => setAddShow(false);
  const handleAddShow = () => {
    setFormData({
      username: "",
      email: "",
      title: "",
      organisation: "",
      country: "",
      permissionSettings: {},
    });
    setAddShow(true);
    setSelectedPermissions([]);
    
  };

  const handlePermissionCheckboxChange = (permission, action) => {
    console.log("permi",permission)
    setFormData(prev => ({
      ...prev,
      permissionSettings: {
        ...prev.permissionSettings,
        [permission]: {
          ...prev.permissionSettings[permission],
          [action]: !prev.permissionSettings[permission][action],
        },
      },
    }));
  };

  const handleDeleteClose = () => setDeleteShow(false);
  const handleDeleteShow = (user) => {
    setCurrentUser(user);
    setDeleteShow(true);
  };

  useEffect(() => {
    fetchUsers();
    fetchPermissions();
  }, [currentPage, pageSize]);

  const fetchPermissions = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/getAllUserPermissions`);
      setPermissions(response.data);
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  const fetchUsers = async () => {
    try {
      const token = sessionStorage.getItem("token"); // Retrieve token from session storage
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/getSubAdminList`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add token to Authorization header
          },
          params: {
            page: currentPage,
            limit: pageSize,
          },
        }
      );
      setUsers(response.data.users);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handlePermissionChange = (selected) => {
    console.log("selected",selected)
    setSelectedPermissions(selected);
    const updatedPermissionSettings = {};
    selected.forEach((perm) => {
      updatedPermissionSettings[perm.value] = {
        read: false,
        update: false,
        delete: false,
        add: false,
      };
    });
    setFormData(prev => ({
      ...prev,
      permissionSettings: updatedPermissionSettings,
    }));
  };

  const handleCountryChange = (country) => {
    setFormData({
      ...formData,
      country: country,
    });
  };

  const validateForm = () => {
    let formErrors = {};
    if (!formData.username) formErrors.username = "Name is required.";
    if (!formData.email) formErrors.email = "Email is required.";
    if (!formData.title) formErrors.title = "Title is required.";
    if (!formData.organisation) formErrors.organisation = "Organisation is required.";
    if (!formData.country) formErrors.country = "Country is required.";
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleAddUser = async () => {
    if (!validateForm()) return;
    
    const updatedFormData = {
        ...formData, // Spread the existing formData fields
        userType: 'subAdmin', // Add userType field
      };

    try {
      const token = sessionStorage.getItem("token"); // Retrieve token from session storage
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/send-set-password-link`,
        updatedFormData,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add token to Authorization header
          },
        }
      );
      handleAddClose();
      toast.success("Password setup link has been sent to your Email");
    } catch (error) {
      console.error("Error adding user:", error);
      toast.error("Failed to add user.");
    }
  };

  const handleEditUser = async () => {
    if (!validateForm()) return;

    try {
      const token = sessionStorage.getItem("token");
    
      await axios.put(
        `${process.env.REACT_APP_BASE_URL}/editSubAdmin/${currentUser.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchUsers();
      handleEditClose();
      toast.success("User updated successfully!");
    } catch (error) {
      console.error("Error editing user:", error);
      toast.error("Failed to update user.");
    }
  };

  const handleDeleteUser = async () => {
    try {
      const token = sessionStorage.getItem("token");
      await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/deleteSubAdmin/${currentUser.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchUsers();
      handleDeleteClose();
      toast.success("User deleted successfully!");
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error("Failed to delete user.");
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };


  const userPermissions = useSelector((state) => state.getUser.user?.UserPermissions || []);



  const currentPermission = userPermissions.find(
    (perm) => `/pages/${perm.routeName}` === location.pathname
  );

  const hasAnyPermission = currentPermission?.UserPermissionsRelation?.canRead ||
  currentPermission?.UserPermissionsRelation?.canEdit ||
  currentPermission?.UserPermissionsRelation?.canAdd ||
  currentPermission?.UserPermissionsRelation?.canDelete;

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#ffffff' : '#ffffff',  // Text color
      backgroundColor: state.isSelected || state.isFocused ? '#1d2835' : '#1d2835',  // Background on hover
      fontSize: '16px',
      padding: 10,
      cursor: 'pointer',  // Change cursor to pointer to indicate hover
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#1d2835',  // Background color of the dropdown menu
      maxHeight: 200,  // Set a max height for the menu to enable scrolling
      overflowY: 'auto',  // Enable vertical scrollbar
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: '#1d2835',  // Control background
      color: '#ffffff',
      fontSize: '16px',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#ffffff',
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#1d2835',  // Background color of selected items in multi-select
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#ffffff',  // Text color of selected items in multi-select
    }),
  };
  
  

  return (
    <div>
      <Sidebar />
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/">PAGES</a>
            </li>
            <li className="breadcrumb-item active">Sub Admin</li>
          </ul>
          <h1 className="page-header mb-0">Sub Admin</h1>
        </div>
        {currentPermission?.UserPermissionsRelation?.canAdd &&

        <div className="ms-auto">
          <a href="#/" className="btn btn-outline-theme" onClick={handleAddShow}>
            <i className="fa fa-plus-circle fa-fw me-1"></i> ADD
          </a>
        </div>}
      </div>

      <Card>
        <div className="tab-content p-4">
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="border-top-0 pt-0 pb-2">Name</th>
                  <th className="border-top-0 pt-0 pb-2">Email</th>
                  <th className="border-top-0 pt-0 pb-2">Title</th>
                  <th className="border-top-0 pt-0 pb-2">Organisation</th>
                  <th className="border-top-0 pt-0 pb-2">Country</th>
                  {hasAnyPermission && (
                  <th className="border-top-0 pt-0 pb-2">Actions</th>)}
                </tr>
              </thead>
              <tbody>
                {users?.length > 0 ? (
                  users?.map((user) => (
                    <tr key={user.id}>
                      <td className="align-middle">{user.username || "N/A"}</td>
                      <td className="align-middle">{user.email || "N/A"}</td>
                      <td className="align-middle">{user.title || "N/A"}</td>
                      <td className="align-middle">{user.organisation || "N/A"}</td>
                      <td className="align-middle">{user.country || "N/A"}</td>
                      <td>
                        <div className="action-btn-group d-flex">
                        {currentPermission?.UserPermissionsRelation?.canEdit &&
                          <button
                            className="btn btn-edit border border-warning text-warning px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center"
                            onClick={() => handleEditShow(user)}
                          >
                            Edit
                          </button>}
                          {currentPermission?.UserPermissionsRelation?.canRead &&
                          <button
                            className="btn btn-show border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center mx-2"
                            onClick={() => handleShow(user)}
                          >
                            Show
                          </button>}
                          {currentPermission?.UserPermissionsRelation?.canDelete &&
                          <button
                            className="btn btn-delete border border-danger text-danger px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center"
                            onClick={() => handleDeleteShow(user)}
                          >
                            Delete
                          </button>}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center">
                      No Users Found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {users?.length > 0 ? (      <div className="pagination-controls d-flex justify-content-center mt-4">
            <button
              className="btn btn-outline-secondary"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="mx-3">
              Page {currentPage} of {totalPages}
            </span>
            <button
              className="btn btn-outline-secondary"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>):(null)}

    
        </div>
      </Card>

      <Modal show={show} onHide={handleClose} className="right-side-modal" scrollable={true}>
        <Modal.Header closeButton>
          <Modal.Title>Show Sub Admin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group-area">
            <div className="form-group">
              <label><strong>Name:</strong></label>
              <p>{currentUser?.username || "N/A"}</p>
            </div>
            <div className="form-group">
              <label><strong>Email:</strong></label>
              <p>{currentUser?.email || "N/A"}</p>
            </div>
            <div className="form-group">
              <label><strong>Title:</strong></label>
              <p>{currentUser?.title || "N/A"}</p>
            </div>
            <div className="form-group">
              <label><strong>Organisation:</strong></label>
              <p>{currentUser?.organisation || "N/A"}</p>
            </div>
            <div className="form-group">
              <label><strong>Country:</strong></label>
              <p>{currentUser?.country || "N/A"}</p>
            </div>
            {/* <div className="form-group">
              <label><strong>Subscription:</strong></label>
              <p>{currentUser?.Subscription?.name || "N/A"}</p>
            </div> */}
          </div>
        </Modal.Body>
      </Modal>

      {/* Add User Modal */}
      <Modal show={addShow} onHide={handleAddClose} scrollable={true}  className="right-side-modal">
        <Modal.Header closeButton>
          <Modal.Title>Add New Sub Admin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="mb-3">
              <label className="form-label">Name</label>
              <input
                type="text"
                className={`form-control ${errors.username ? "is-invalid" : ""}`}
                name="username"
                value={formData.username}
                onChange={handleInputChange}
              />
              {errors.username && <div className="invalid-feedback">{errors.username}</div>}
            </div>
            <div className="mb-3">
              <label className="form-label">Email</label>
              <input
                type="email"
                className={`form-control ${errors.email ? "is-invalid" : ""}`}
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
              {errors.email && <div className="invalid-feedback">{errors.email}</div>}
            </div>
            <div className="mb-3">
              <label className="form-label">Title</label>
              <input
                type="text"
                className={`form-control ${errors.title ? "is-invalid" : ""}`}
                name="title"
                value={formData.title}
                onChange={handleInputChange}
              />
              {errors.title && <div className="invalid-feedback">{errors.title}</div>}
            </div>
            <div className="mb-3">
              <label className="form-label">Organisation</label>
              <input
                type="text"
                className={`form-control ${errors.organisation ? "is-invalid" : ""}`}
                name="organisation"
                value={formData.organisation}
                onChange={handleInputChange}
              />
              {errors.organisation && (
                <div className="invalid-feedback">{errors.organisation}</div>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label">Country</label>
              <CountryDropdown
                value={formData.country}
                onChange={handleCountryChange}
                className={`form-control ${errors.country ? "is-invalid" : ""} subscription-select`}
              />
              {errors.country && <div className="invalid-feedback">{errors.country}</div>}
            </div>

            <div className="mb-3">
              <label htmlFor="permissions" className="form-label">Permissions</label>
              <Select
                isMulti
                options={permissions.map((perm) => ({ value: perm.id, label: perm.name }))}
                value={selectedPermissions}
                onChange={handlePermissionChange}
                className="basic-multi-select subscription-select " 
                classNamePrefix="select"
                styles={customStyles}
              />
            </div>
            {selectedPermissions.map((perm) => (
              <div key={perm.value} className="mb-3">
                <h5>{perm.label}</h5>
                <label className="form-check-label">
                  <input
                    type="checkbox"
                    className="form-check-input subscription-select"
                    checked={formData.permissionSettings[perm.value]?.read || false}
                    onChange={() => handlePermissionCheckboxChange(perm.value, "read")}
                  />
                  Read
                </label>
                <label className="form-check-label ms-2">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={formData.permissionSettings[perm.value]?.update || false}
                    onChange={() => handlePermissionCheckboxChange(perm.value, "update")}
                  />
                  Update
                </label>
                <label className="form-check-label ms-2">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={formData.permissionSettings[perm.value]?.delete || false}
                    onChange={() => handlePermissionCheckboxChange(perm.value, "delete")}
                  />
                  Delete
                </label>
                <label className="form-check-label ms-2">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={formData.permissionSettings[perm.value]?.add || false}
                    onChange={() => handlePermissionCheckboxChange(perm.value, "add")}
                  />
                  Add
                </label>
              </div>
            ))}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleAddClose}>
            Close
          </button>
          <button className="btn btn-primary" onClick={handleAddUser}>
            Add User
          </button>
        </Modal.Footer>
      </Modal>

      {/* Edit User Modal */}
      <Modal show={editShow} onHide={handleEditClose} scrollable={true}  className="right-side-modal">
        <Modal.Header closeButton>
          <Modal.Title>Edit Sub Admin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="mb-3">
              <label className="form-label">Name</label>
              <input
                type="text"
                className={`form-control ${errors.username ? "is-invalid" : ""}`}
                name="username"
                value={formData.username}
                onChange={handleInputChange}
              />
              {errors.username && <div className="invalid-feedback">{errors.username}</div>}
            </div>
            <div className="mb-3">
              <label className="form-label">Email</label>
              <input
                type="email"
                className={`form-control ${errors.email ? "is-invalid" : ""}`}
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
              {errors.email && <div className="invalid-feedback">{errors.email}</div>}
            </div>
            <div className="mb-3">
              <label className="form-label">Title</label>
              <input
                type="text"
                className={`form-control ${errors.title ? "is-invalid" : ""}`}
                name="title"
                value={formData.title}
                onChange={handleInputChange}
              />
              {errors.title && <div className="invalid-feedback">{errors.title}</div>}
            </div>
            <div className="mb-3">
              <label className="form-label">Organisation</label>
              <input
                type="text"
                className={`form-control ${errors.organisation ? "is-invalid" : ""}`}
                name="organisation"
                value={formData.organisation}
                onChange={handleInputChange}
              />
              {errors.organisation && (
                <div className="invalid-feedback">{errors.organisation}</div>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label">Country</label>
              <CountryDropdown
                value={formData.country}
                onChange={handleCountryChange}
                className={`form-control ${errors.country ? "is-invalid" : ""} subscription-select`}
                
              />
              {errors.country && <div className="invalid-feedback">{errors.country}</div>}
            </div>
            <div className="mb-3">
              <label htmlFor="permissions" className="form-label">Permissions</label>
              <Select
                isMulti
                options={permissions.map((perm) => ({ value: perm.id, label: perm.name }))}
                value={selectedPermissions}
                onChange={handlePermissionChange}
                className="basic-multi-select " 
                classNamePrefix="select"
                styles={customStyles}
              />
            </div>
            {selectedPermissions.map((perm) => (
              <div key={perm.value} className="mb-3">
                <h5>{perm.label}</h5>
                <label className="form-check-label">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={formData.permissionSettings[perm.value]?.read || false}
                    onChange={() => handlePermissionCheckboxChange(perm.value, "read")}
                  />
                  Read
                </label>
                <label className="form-check-label ms-2">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={formData.permissionSettings[perm.value]?.update || false}
                    onChange={() => handlePermissionCheckboxChange(perm.value, "update")}
                  />
                  Update
                </label>
                <label className="form-check-label ms-2">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={formData.permissionSettings[perm.value]?.delete || false}
                    onChange={() => handlePermissionCheckboxChange(perm.value, "delete")}
                  />
                  Delete
                </label>
                <label className="form-check-label ms-2">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={formData.permissionSettings[perm.value]?.add || false}
                    onChange={() => handlePermissionCheckboxChange(perm.value, "add")}
                  />
                  Add
                </label>
              </div>
            ))}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleEditClose}>
            Close
          </button>
          <button className="btn btn-primary" onClick={handleEditUser}>
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>

      {/* Delete User Modal */}
      <Modal show={deleteShow} onHide={handleDeleteClose} >
        <Modal.Header closeButton>
          <Modal.Title>Delete Sub Admin</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this Sub Admin?</Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleDeleteClose}>
            Close
          </button>
          <button className="btn btn-danger" onClick={handleDeleteUser}>
            Delete
          </button>
        </Modal.Footer>
      </Modal>

      <ToastContainer />
    </div>
  );
}

export default ManageSubAdmins;
