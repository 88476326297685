import React, { useState, useEffect } from "react";
import axios from "axios";
import { Card } from "./../../components/card/card.jsx";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import Sidebar from "../../components/sidebar/sidebar.jsx";
import { FaFileAlt } from "react-icons/fa"; // Icon for documents
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function AddDoc() {
  const [addShow, setAddShow] = useState(false);
  const handleAddClose = () => setAddShow(false);
  const handleAddShow = () =>{ 
    setErrorMessages({})
    setAddShow(true);
  }

  const [editShow, setEditShow] = useState(false);
  const handleEditClose = () => setEditShow(false);

  const [deleteShow, setDeleteShow] = useState(false);
  const handleDeleteClose = () => setDeleteShow(false);
  const handleDeleteShow = (document) => {
    setCurrentDocument(document);
    setSelectedFiles([]); 
    setDeleteShow(true);
  };

  const [docsShow, setDocsShow] = useState(false);
  const handleDocsClose = () => setDocsShow(false);


  

  // State for permissions
  const [permissions, setPermissions] = useState([]);
  const [parentPermissions, setParentPermissions] = useState([]);
  const [subPermissions, setSubPermissions] = useState([]);
  const [removeDocs, setRemoveDocs] = useState([]); // To keep track of removed documents

  // Selected parent and sub-section
  const [selectedParent, setSelectedParent] = useState("");
  const [selectedSub, setSelectedSub] = useState("");

  // State to track selected files
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [errorMessages, setErrorMessages] = useState({}); // State for error messages
  // State to track tags as string
  const [tags, setTags] = useState("");

  // State to track title and type
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [docType, setDocType] = useState("doc");

  // State to track current document for editing
  const [currentDocument, setCurrentDocument] = useState(null);

  // State to track fetched documents and pagination
  const [documents, setDocuments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const token = sessionStorage.getItem('token');

  // Fetch permissions and document list on component mount
  useEffect(() => {
    fetchPermissions();
    fetchDocuments(currentPage);
  }, [currentPage]);

  // Pre-populate subcategories when editing
  useEffect(() => {
    if (editShow && currentDocument?.mainCategoryId) {
      const subPermissions = permissions.filter(perm => perm.parentId === parseInt(currentDocument.mainCategoryId));
      setSubPermissions(subPermissions);
    }
  }, [editShow, currentDocument?.mainCategoryId, permissions]);

  // Fetch all permissions using Axios
  const fetchPermissions = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/getAllPermissions`);
      const allPermissions = response.data;

      const parentPermissions = allPermissions.filter(perm => perm.parentId === null);
      setPermissions(allPermissions);
      setParentPermissions(parentPermissions);
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  // Fetch documents using Axios with pagination
  const fetchDocuments = async (page) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/getDocList?page=${page}&limit=5&type=${docType}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { documents, currentPage, totalPages } = response.data;

      setDocuments(documents);
      setCurrentPage(currentPage);

      setTotalPages(totalPages);
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  // Handle parent category change (works for both Add and Edit)
  const handleParentChange = (e) => {
    const selectedParentId = e.target.value;
  
    
    if (editShow) {
   
      setCurrentDocument((prevDocument) => ({
        ...prevDocument,
        mainCategoryId: selectedParentId,
        subCategoryId: "", // Reset subcategory when parent changes
      }));
    } else {
      setSelectedParent(selectedParentId);
      setSelectedSub("");
    }

    const subPermissions = permissions.filter(perm => perm.parentId === parseInt(selectedParentId));
    setSubPermissions(subPermissions);
  };

  // Handle sub-section change (works for both Add and Edit)
  const handleSubChange = (e) => {
    const selectedSubId = e.target.value;
    if (editShow) {
      setCurrentDocument((prevDocument) => ({
        ...prevDocument,
        subCategoryId: selectedSubId,
      }));
    } else {
      setSelectedSub(selectedSubId);
    }
  };

  // Handle file selection
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files); 
    const newFiles = [...selectedFiles, ...files];
    if (newFiles.length > 10) {
      alert("You can select a maximum of 10 files.");
      return;
    }
    setSelectedFiles(newFiles); 
  };

  // Remove a selected file
  const handleRemoveFile = (index) => {
    const updatedFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(updatedFiles);
  };

  // Handle tags input
  const handleTagsChange = (e) => {
    setTags(e.target.value); 
  };

  const extractTags = (tagsString) => {
    return tagsString.split("#").map(tag => tag.trim()).filter(tag => tag);
  };

   // Validation Function
   const validateForm = (type) => {
  
    const errors = {};
    if(type=="add"){
    if (!title) errors.title = 'Title is required';
    if (!selectedParent) errors.parent = 'Parent Section is required';
    // if (!selectedSub) errors.subsection = 'Sub Section is required';
    if (!tags) errors.tags = 'Tags are required';
    if (!description) errors.description = 'Description is required';
   
    if (selectedFiles?.length === 0) errors.files = 'At least one file must be selected or existing files must be present';
    }
    if(type=="edit"){

  
      if (!currentDocument?.title) errors.title = 'Title is required';
      if (!currentDocument?.mainCategoryId) errors.parent = 'Parent Section is required';
      // if (!currentDocument?.subCategoryId) errors.subsection = 'Sub Section is required';
      if (!currentDocument?.tags) errors.tags = 'Tags are required';
      if (!currentDocument?.description) errors.description = 'Description is required';
      if (selectedFiles.length === 0 && currentDocument.docs.length === 0) {
        errors.files = 'At least one file must be selected or existing files must be present';
      }
    }
    setErrorMessages(errors);
    const a=Object.keys(errors).length === 0
    console.log("aa",a,errors)
    return Object.keys(errors).length === 0; // return true if no errors
  };

  // Handle form submission for adding the document
  const handleAddDocument = async () => {
  

    if (!validateForm("add")) return;

    const tagsArray = extractTags(tags);

    const formData = new FormData();
    formData.append("title", title);
    formData.append("mainCategoryId", selectedParent);
    formData.append("subCategoryId", selectedSub);
    formData.append("tags", JSON.stringify(tagsArray));
    formData.append("type", docType);
    formData.append("description", description);

    selectedFiles.forEach(file => {
      formData.append("documents", file);
    });

    try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/addDocument`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`, 
        },
      });
      if (response.status === 201) {
        toast.success('Document added successfully');
        handleAddClose();
        setSelectedFiles([]);
     
        setTags("");
        setTitle("")
        setSelectedParent("")
        setSelectedSub("")
        setTags("")
        setDescription("")
      

        fetchDocuments(currentPage);
      }
     
    } catch (error) {
      console.error("Error adding document:", error);
      toast.error(error.response?.data?.message || 'Error adding document');
    }
  };

  // Handle form submission for editing the document
  const handleEditDocument = async () => {

    if (!validateForm("edit")) return;


    const tagsToSave = currentDocument.tags.map(tag => tag.startsWith('#') ? tag : `${tag}`);


    const formData = new FormData();
    formData.append("title", currentDocument.title);
    formData.append("mainCategoryId", currentDocument.mainCategoryId);
    formData.append("subCategoryId", currentDocument.subCategoryId);
    formData.append("tags", JSON.stringify(tagsToSave));
    formData.append("description", currentDocument.description);
    formData.append("removeDocs",JSON.stringify( removeDocs));
    formData.append("type", docType);

    
    
    selectedFiles.forEach(file => {
      formData.append("documents", file);
    });

    try {
        const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/editDoc/${currentDocument.id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`, 
        },
      });
      if (response.status === 200) {
        toast.success('Document updated successfully');
        setEditShow(false);
        fetchDocuments(currentPage);
      }
    
    } catch (error) {
      console.error("Error updating document:", error);
      toast.error(error.response?.data?.message || 'Error updating document');
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleDeleteDocument = async (id) => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/deleteDoc/${id}?type=${docType}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        toast.success('Document deleted successfully');
        handleDeleteClose();
        setEditShow(false);
        fetchDocuments(currentPage);
      
      }
    } catch (error) {
      toast.error(error.response?.data?.message || 'Error deleting document');
    }
  };

  // Handle opening the edit modal with the selected document's details
  const handleEditShow = (document) => {
    setCurrentDocument(document);
    setErrorMessages({})
    setSelectedFiles([]); 
    setEditShow(true);
  };

  const handleDocsShow = (document) =>{
    setCurrentDocument(document);
    setSelectedFiles([]); 

    setDocsShow(true);
  } 

  const handleRemoveExistingDoc = (index) => {
    const removedDoc = currentDocument.docs[index];
    setRemoveDocs([...removeDocs, removedDoc]);
    setCurrentDocument({
      ...currentDocument,
      docs: currentDocument.docs.filter((_, i) => i !== index),
    });
  };

  return (
    <div>
      <Sidebar />
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/">PAGES</a>
            </li>
            <li className="breadcrumb-item active">DOCUMENTS</li>
          </ul>
          <h1 className="page-header mb-0">Documents</h1>
        </div>

        <div className="ms-auto">
          <button className="btn btn-outline-theme" onClick={handleAddShow}>
            <i className="fa fa-plus-circle fa-fw me-1"></i> Add Documents
          </button>
        </div>
      </div>

      <Card>
        <div className="tab-content p-4">
          <div className="table-responsive">
            <table className="table table-hover">
              
              <thead>
                <tr>
                  <th className="border-top-0 pt-0 pb-2">Title</th>
                  <th className="border-top-0 pt-0 pb-2">Parent Section</th>
                  <th className="border-top-0 pt-0 pb-2">Sub Section</th>
                 
                  <th className="border-top-0 pt-0 pb-2">Created By</th>
                  <th className="border-top-0 pt-0 pb-2">Tags</th>
                  <th className="border-top-0 pt-0 pb-2">Description</th>
                  <th className="border-top-0 pt-0 pb-2">Action</th>
                </tr>
              </thead>
              <tbody>
                 {documents?.length > 0 ? (
                documents?.map((doc) => (
                  <tr key={doc.id}>
                    <td className="align-middle">{doc?.title}</td>
                    <td className="align-middle">{doc?.mainCategory?.name}</td>
                    <td className="align-middle">{doc?.subCategory?.name}</td>
                    
                    <td className="align-middle">{doc?.user?.username}</td>
                    <td className="align-middle">
                      {doc.tags && doc.tags.map((tag, index) => (
                        <span key={index} className="badge me-1"  style={{ backgroundColor: '#6c757d', color: '#fff', border: '1px solid #fff' }}>#{tag}</span>
                      ))}
                    </td>
                      <td className="align-middle">{doc?.description||"N/A"}</td>
                    <td className="align-middle">
                    <div className="action-btn-group d-flex">
                    <button
                          className="btn btn-show border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center mx-2"
                          onClick={() => handleDocsShow(doc)}
                        >
                          Show
                        </button>
                      <button className="btn btn-edit border border-warning text-warning px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center" onClick={() => handleEditShow(doc)}>Edit</button>
                      <button className="btn btn-delete border border-danger text-danger px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center" onClick={() => handleDeleteShow(doc)}>Delete</button>
                    </div>
                    </td>
                  </tr>
                ))):(
                  <tr>
                  <td colSpan="6" className="text-center">
                    No Documents Found
                  </td>
                </tr>
  )}
              </tbody>
            </table>
          </div>
          {documents?.length>0?(  <div className="pagination-controls d-flex justify-content-center mt-4">
            <button
              className="btn btn-outline-secondary"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="mx-3">
              Page {currentPage} of {totalPages}
            </span>
            <button
              className="btn btn-outline-secondary"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>):(null)}
        
        </div>
      </Card>

      {/* Add Document Modal */}
      <Modal show={addShow} onHide={handleAddClose} className="right-side-modal" scrollable={true}>
        {/* Modal content similar to Edit modal but for adding documents */}
        <Modal.Header closeButton>
          <Modal.Title>Add Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="add-document-form">
            <div className="form-group">
              <label>Title<span className="text-danger">*</span></label>
              <input
                type="text"
                placeholder="Document Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className={`form-control ${errorMessages.title ? 'is-invalid' : ''}`}
              />
              {errorMessages?.title && <div className="invalid-feedback" >{errorMessages?.title}</div>}
            </div>

            <div className="form-group ">
              <label>Main Section <span className="text-danger">*</span></label>
              <select className={`form-select ${errorMessages.parent ? 'is-invalid' : ''}`} value={selectedParent} onChange={handleParentChange}>
                <option value="">Select Parent Section</option>
                {parentPermissions.map((perm) => (
                  <option key={perm.id} value={perm.id}>
                    {perm.name}
                  </option>
                ))}
              </select>
              {errorMessages.parent && <div className="invalid-feedback">{errorMessages.parent}</div>}
            </div>

            <div className="form-group">
              <label>Sub Section<span className="text-danger">*</span></label>
              <select  className={`form-select ${errorMessages.subsection ? 'is-invalid' : ''}`} value={selectedSub} onChange={handleSubChange} disabled={!selectedParent}>
                <option value="">Select Sub Section</option>
                {subPermissions.map((subPerm) => (
                  <option key={subPerm.id} value={subPerm.id}>
                    {subPerm.name}
                  </option>
                ))}
              </select>
              {errorMessages.subsection && <div className="invalid-feedback">{errorMessages.subsection}</div>}
            </div>

            <div className="form-group">
              <label>Tags <span className="text-danger">*</span></label>
              <textarea
                placeholder="Add hashtags (e.g., #tag1 #tag2)"
                value={tags}
                onChange={handleTagsChange}
                className={`form-control ${errorMessages.tags ? 'is-invalid' : ''}`}
              />
               {errorMessages.tags && <div className="invalid-feedback">{errorMessages.tags}</div>}
            </div>

            <div className="form-group">
              <label>Description<span className="text-danger">*</span></label>
              <textarea
               
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className={`form-control ${errorMessages.description ? 'is-invalid' : ''}`}
              />
               {errorMessages.description && <div className="invalid-feedback">{errorMessages.description}</div>}
            </div>

            <div className="form-group">
              <label className="form-label">Documents <span className="text-danger">*</span></label> <br />
              <small>maximum 10 files upload only</small>
              <div className="upload-btn-wrapper mt-2">
                <button className="uploadBtn btn btn-outline-theme">
                  <i className="fas fa-lg fa-fw me-2 fa-upload"></i> Upload
                </button>
                <input
                  type="file"
                  multiple
                  onChange={handleFileChange}
                  className="form-control"
                />
              </div>
              {errorMessages.files && <div className="invalid-feedback d-block">{errorMessages.files}</div>}
            </div>

            {selectedFiles.length > 0 && (
              <div className="selected-files mt-3">
                <h6>Selected Documents ({selectedFiles.length}/10)</h6>
                <ul className="list-group">
                  {selectedFiles.map((file, index) => (
                    <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                      <FaFileAlt className="me-2" /> {file.name}
                      <button
                        className="btn btn-sm btn-danger"
                        onClick={() => handleRemoveFile(index)}
                      >
                        Remove
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <div className="form-button mt-3">
              <button className="btn btn-outline-theme" onClick={handleAddDocument}> + ADD</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Edit Document Modal */}
      <Modal show={editShow} onHide={handleEditClose} className="right-side-modal" scrollable={true}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="add-document-form">
            <div className="form-group">
              <label>Title</label>
              <input
                type="text"
                placeholder="Document Title"
                value={currentDocument?.title || ""}
                onChange={(e) => setCurrentDocument({...currentDocument, title: e.target.value})}
                className={`form-control ${errorMessages.title ? 'is-invalid' : ''}`}
              />
                {errorMessages.title && <div className="invalid-feedback">{errorMessages.title}</div>}
            </div>

            <div className="form-group">
              <label>Main Section</label>
              <select 
               className={`form-select ${errorMessages.parent ? 'is-invalid' : ''}`}
                value={currentDocument?.mainCategoryId || ""} 
                onChange={handleParentChange}>
                <option value="">Select Parent Section</option>
                {parentPermissions.map((perm) => (
                  <option key={perm.id} value={perm.id}>
                    {perm.name}
                  </option>
                ))}
              </select>
              {errorMessages.parent && <div className="invalid-feedback">{errorMessages.parent}</div>}
            </div>

            <div className="form-group ">
              <label>Sub Section</label>
              <select 
                className={`form-select ${errorMessages.subsection ? 'is-invalid' : ''}`}
                value={currentDocument?.subCategoryId || ""} 
                onChange={handleSubChange} 
                disabled={!currentDocument?.mainCategoryId}>
                <option value="">Select Sub Section</option>
                {subPermissions.map((subPerm) => (
                  <option key={subPerm.id} value={subPerm.id}>
                    {subPerm.name}
                  </option>
                ))}
              </select>
              {errorMessages.subsection && <div className="invalid-feedback">{errorMessages.subsection}</div>}
            </div>

            <div className="form-group">
              <label>Tags</label>
              <textarea
                placeholder="Add hashtags (e.g., #tag1 #tag2)"
                value={currentDocument?.tags?.map(tag => `#${tag}`).join(" ") || ""}
                onChange={(e) => {
                    const newTags = e.target.value.split(" ").map(tag => tag.replace(/^#/, '')); // Remove # before saving
                    setCurrentDocument({...currentDocument, tags: newTags});
                  }}
                  className={`form-control ${errorMessages.tags ? 'is-invalid' : ''}`}
              />
               {errorMessages.tags && <div className="invalid-feedback">{errorMessages.tags}</div>}
            </div>

            <div className="form-group">
              <label>Description</label>
              <textarea
              
                value={currentDocument?.description}
                onChange={(e) => setCurrentDocument({...currentDocument, description: e.target.value})}
                className={`form-control ${errorMessages.description ? 'is-invalid' : ''}`}
              />
              {errorMessages.description && <div className="invalid-feedback">{errorMessages.description}</div>}
            </div>

                {/* Existing Documents List */}
                <div className="form-group">
              <label>Existing Documents</label>
              <ul className="list-group">
                {currentDocument?.docs?.map((docUrl, index) => {
                  const parts = docUrl.split("/");
                  const fileNameWithExtension = parts[parts.length - 1];
                  const fileName = fileNameWithExtension.split("_")[1];

                  return (
                    <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                      <FaFileAlt className="me-2" /> {fileName}
                      <button
                        className="btn btn-sm btn-danger"
                        onClick={() => handleRemoveExistingDoc(index)}
                      >
                        Remove
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>

            {/* Upload New Documents */}
            <div className="form-group">
              <label className="form-label">Upload New Documents</label>
              <small>maximum 10 files upload only</small>
              <div className="upload-btn-wrapper mt-2">
                <button className="uploadBtn btn btn-outline-theme">
                  <i className="fas fa-lg fa-fw me-2 fa-upload"></i> Upload
                </button>
                <input
                  type="file"
                  multiple
                  onChange={handleFileChange}
                  className="form-control"
                />
              </div>
              {errorMessages.files && <div className="invalid-feedback d-block">{errorMessages.files}</div>}
            </div>

            {selectedFiles.length > 0 && (
              <div className="selected-files mt-3">
                <h6>Selected Documents ({selectedFiles.length}/10)</h6>
                <ul className="list-group">
                  {selectedFiles.map((file, index) => (
                    <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                      <FaFileAlt className="me-2" /> {file.name}
                      <button
                        className="btn btn-sm btn-danger"
                        onClick={() => handleRemoveFile(index)}
                      >
                        Remove
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}


            <div className="form-button mt-3">
              <button className="btn btn-outline-theme" onClick={handleEditDocument}> Save Changes</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

       {/* Delete Confirmation Modal */}
       <Modal show={deleteShow} onHide={handleDeleteClose} className="delete-modal">
        <Modal.Header closeButton>
          <Modal.Title>Delete Document List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this Document list?</p>
          <div className="delete-btn-group">
            <button className="btn dlt-btn dlt-btn-close" onClick={handleDeleteClose}>
              Cancel
            </button>
            <button className="btn dlt-btn" onClick={() => handleDeleteDocument(currentDocument.id)}>Delete</button>
          </div>
        </Modal.Body>
      </Modal>

  {/* Document List Modal */}
<Modal show={docsShow} onHide={handleDocsClose} className="right-side-modal">
  <Modal.Header closeButton>
    <Modal.Title>Document List</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <div className="docs-list-area">
     
    <ul>
            {currentDocument?.docs?.map((docUrl, index) => {
              // Extract the name from the URL
              const parts = docUrl.split("/"); // Split the URL by "/"
              const fileNameWithExtension = parts[parts.length - 1]; // Get the last part (e.g., "1727294199263_1727171672475_env")
              const fileName = fileNameWithExtension.split("_")[1]; // Split by "_" and take the second part

              return (
                <li key={index}>
                  {/* Each document URL is clickable */}
                  <a href={docUrl} target="_blank" rel="noopener noreferrer">
                    {fileName} {/* Display the extracted name */}
                  </a>
                </li>
              );
            })}
          </ul>
       
    </div>
  </Modal.Body>
</Modal>

<ToastContainer />


    </div>
  );
}

export default AddDoc;
