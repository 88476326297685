import React, { useEffect, useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from "react-redux";
import { AppSettings } from "../../config/app-settings.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';

function PagesResetPassword() {
  const theme = useSelector((state) => state.theme.theme);
  const context = useContext(AppSettings);
  const navigate = useNavigate();

  // State for passwords and visibility toggles
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  
  // State for error messages
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [apiError, setApiError] = useState('');
  const [isTokenExpired, setIsTokenExpired] = useState(false);

  useEffect(() => {
    // Hide header and sidebar
    context.setAppHeaderNone(true);
    context.setAppSidebarNone(true);

    // Extract token from URL
    console.log("windows",window.location)
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get('token');
    const email = searchParams.get('email');
    console.log("windows",token,email)


    // Check token validity
    if (token) {
      checkTokenValidity(token);
    } else {
      setIsTokenExpired(true);
    }

    // Reset settings on unmount
    return () => {
      context.setAppHeaderNone(false);
      context.setAppSidebarNone(false);
    };
  }, [context]);

  // Function to check token validity
  const checkTokenValidity = async (token) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/checkExpirySetUser`, { token });
      if (response.data.expired) {
        setIsTokenExpired(true);
      }
    } catch (error) {
      setApiError('An error occurred while checking token validity.');
    }
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => setPasswordVisible(!passwordVisible);
  const toggleConfirmPasswordVisibility = () => setConfirmPasswordVisible(!confirmPasswordVisible);


  const isValidPassword = (password) => {
    // At least 8 characters, one uppercase letter, one number, and one special character
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/;
    return passwordRegex.test(password);
  };
  // Validate passwords before submission
  const validatePasswords = () => {
    let isValid = true;
    setPasswordError('');
    setConfirmPasswordError('');

    if (!password) {
      setPasswordError('Password is required.');
      isValid = false;
    } else if (!isValidPassword(password)) {
      setPasswordError('Password must be at least 8 characters long and include at least one uppercase letter, one number, and one special character.');
      isValid = false;
    }

    if (!confirmPassword) {
      setConfirmPasswordError('Confirm Password is required.');
      isValid = false;
    } else if (password !== confirmPassword) {
      setConfirmPasswordError('Passwords do not match.');
      isValid = false;
    }

    return isValid;
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validatePasswords()) return;

    try {
      const searchParams = new URLSearchParams(window.location.search);
      const token = searchParams.get('token');
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/resetPassword`, { token, password });

      if (response.status === 200) {
        toast.success('Password reset successfully!');
        setTimeout(() => {
          navigate('/pages/login');
        }, 2000); // 1.5 seconds delay
      } else {
        setApiError('Failed to reset password. Please try again.');
      }
    } catch (error) {
      setApiError('An error occurred. Please try again.');
    }
  };

  if (isTokenExpired) {
    return (
      <div className="error-page">
        <div className="error-page-content">
          <h2>Session has expired. Please request a new reset link.</h2>
        </div>
      </div>
    );
  }

  return (
    <div className="login-app-content">
      <ToastContainer />
      <div className="row justify-content-center" style={{ minHeight: "100vh" }}>
        <div className="col-md-12">
          <Link to="/">
            <img
              src={theme === "dark" ? "/assets/img/dashboard/dark_logo_web.jpg" : "/assets/img/dashboard/light_logo_web.png"}
              style={{ height: "60px" }}
              alt="Brand Logo"
            />
          </Link>
        </div>
        <div className="col-md-4 col-12" style={{ alignItems: "center" }}>
          <form onSubmit={handleSubmit}>
            <h1 className="text-center">Reset Password</h1>
            <div className="text-inverse text-opacity-50 mb-4 text-center">
              Please enter your new password and confirm it.
            </div>

            {/* Display API error */}
            {apiError && <div className="alert alert-danger">{apiError}</div>}

            <div className="mb-3">
              <label className="form-label">
                Password <span className="text-danger">*</span>
              </label>
              <div className="input-group">
                <input
                  type={passwordVisible ? "text" : "password"}
                  className="form-control form-control-lg bg-white bg-opacity-5"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setPasswordError('');
                  }}
                />
                <span className="input-group-text" onClick={togglePasswordVisibility} style={{ cursor: "pointer" }}>
                  <FontAwesomeIcon icon={passwordVisible ? faEye : faEyeSlash} />
                </span>
              </div>
              {passwordError && <div className="text-danger">{passwordError}</div>}
            </div>

            <div className="mb-3">
              <label className="form-label">
                Confirm Password <span className="text-danger">*</span>
              </label>
              <div className="input-group">
                <input
                  type={confirmPasswordVisible ? "text" : "password"}
                  className="form-control form-control-lg bg-white bg-opacity-5"
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    setConfirmPasswordError(password !== e.target.value ? 'Passwords do not match.' : '');
                  }}
                />
                <span className="input-group-text" onClick={toggleConfirmPasswordVisibility} style={{ cursor: "pointer" }}>
                  <FontAwesomeIcon icon={confirmPasswordVisible ? faEye : faEyeSlash} />
                </span>
              </div>
              {confirmPasswordError && <div className="text-danger">{confirmPasswordError}</div>}
            </div>

            <button type="submit" className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mt-4">
              Reset Password
            </button>
            <div className="mt-2 text-end">
              <Link to="/pages/login" className="ms-auto text-inverse text-decoration-none text-opacity-50">
                Login to Acquire
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default PagesResetPassword;
