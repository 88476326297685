import { createSlice } from '@reduxjs/toolkit';

// Define the initial state of the authentication slice
const initialState = {
  user: null,
  error: null
};

const getUserSlice = createSlice({
  name: 'getUser',
  initialState,
  reducers: {
    getUserSuccess: (state, action) => {
      state.user = action.payload;
      state.error = null;
    },
    getUserFailure: (state, action) => {
      state.user = null;
  
      state.error = action.payload;
    }
  }
});

// Export actions
export const { getUserSuccess, getUserFailure } = getUserSlice.actions;

// Export the reducer
export default getUserSlice.reducer;