import { Card, CardBody } from "./../../components/card/card.jsx";
import React, { useEffect, useState } from "react";
import Sidebar from "../../components/sidebar/sidebar.jsx";
import axios from "axios";
import { FaUser, FaUserTie, FaUserShield, FaUserGraduate } from 'react-icons/fa';

function AdminDashboard() {
  // State for storing subscription data from the API
  const [subscriptions, setSubscriptions] = useState([]);
  const [totalUsersCount, setTotalUsersCount] = useState(0);

  // Fetch data from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Get the token from sessionStorage
        const token = sessionStorage.getItem("token");

        // Make an API request using axios
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/getAdminDashboardData`, {
          headers: {
            Authorization: `Bearer ${token}` // Include token in request headers
          }
        });

        const data = response.data;

        // Calculate total users count
        const total = data.reduce((acc, item) => acc + parseInt(item.userCount, 10), 0);
        setTotalUsersCount(total);
        setSubscriptions(data); // Store the fetched data
      } catch (error) {
        console.error("Error fetching user counts:", error);
      }
    };

    fetchData();
  }, []);

  // Function to get icon based on subscription name
  const getIconByName = (name) => {
    switch (name) {
      case "Free Member":
        return <FaUser className="mb-2" size={40} style={{ color: "#007bff" }} />;
      case "QCR Corporate Member":
        return <FaUserTie className="mb-2" size={40} style={{ color: "#17a2b8" }} />;
      case "QCR Individual Member":
        return <FaUserShield className="mb-2" size={40} style={{ color: "#28a745" }} />;
      case "GQI Member":
        return <FaUserGraduate className="mb-2" size={40} style={{ color: "#ffc107" }} />;
      default:
        return <FaUser className="mb-2" size={40} style={{ color: "#6c757d" }} />; // Default icon
    }
  };

  return (
    <div>
      <Sidebar />
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/">PAGES</a>
            </li>
            <li className="breadcrumb-item active">DASHBOARD</li>
          </ul>
          <h1 className="page-header mb-0">Dashboard</h1>
        </div>
      </div>

      <div className="row">
        {/* Total Users */}
        <div className="col-md-12 mb-4">
          <Card style={{ backgroundColor: "#343a40" }}>
            <CardBody className="text-center">
              <h3 className="card-title" style={{ color: "white" }}>Total Users</h3>
              <p className="card-text" style={{ fontSize: "2rem", color: "white" }}>{totalUsersCount}</p>
            </CardBody>
          </Card>
        </div>

        {/* Render each subscription as a card */}
        {subscriptions.map((subscription) => (
          <div key={subscription.id} className="col-md-3 mb-4">
            <Card className="min-card-dashboard">
              <CardBody className="text-center">
                {getIconByName(subscription.name)} {/* Dynamic icon based on subscription name */}
                <h5 className="card-title">{subscription.name}</h5>
                <p className="card-text">Total Users: {subscription.userCount}</p>
              </CardBody>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AdminDashboard;
