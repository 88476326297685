import React, { useEffect, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { AppSettings } from './../../config/app-settings.js';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { signupStore } from '../../redux/slices/signupSlice.js';
import { CountryDropdown } from 'react-country-region-selector'; // Import CountryDropdown
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // FontAwesome Icon
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'; // Eye Icons

function PagesRegister() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const context = useContext(AppSettings);
  const [redirect, setRedirect] = useState(false);
  const theme = useSelector((state) => state.theme.theme);
  const { pricingId } = useSelector((state) => state.signUp.signUpInfo);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [title, setTitle] = useState('');
  const [company, setCompany] = useState('');
  const [country, setCountry] = useState('United States'); // Default value

    // New state to toggle password visibility
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Error states
  const [apiError, setApiError] = useState('');
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [titleError, setTitleError] = useState('');
  const [companyError, setCompanyError] = useState('');

  useEffect(() => {
    context.setAppHeaderNone(true);
    context.setAppSidebarNone(true);
    context.setAppContentClass('p-0');

    return function cleanUp() {
      context.setAppHeaderNone(false);
      context.setAppSidebarNone(false);
      context.setAppContentClass('');
    };
  }, []);

  // Function to validate fields
  const validateFields = () => {
    let isValid = true;

    if (!name) {
      setNameError('Name is required.');
      isValid = false;
    }

    if (!email) {
      setEmailError('Email is required.');
      isValid = false;
    }

    if (!password) {
      setPasswordError('Password is required.');
      isValid = false;
    }

    if (!confirmPassword) {
      setConfirmPasswordError('Confirm Password is required.');
      isValid = false;
    } else if (password !== confirmPassword) {
      setConfirmPasswordError('Passwords do not match.');
      isValid = false;
    }

    if (!title) {
      setTitleError('Title is required.');
      isValid = false;
    }

    if (!company) {
      setCompanyError('Company/Institution is required.');
      isValid = false;
    }

    // Clear errors after 2 seconds if there are any
    if (!isValid) clearErrors();

    return isValid;
  };

  // Function to handle form submission
  async function handleSubmit(event) {
    event.preventDefault();

    if (!validateFields()) {
      return;
    }

    // Construct the payload
    const payload = {
      name,
      email,
      password,
      title,
      company,
      country,
      subscriptionId: pricingId,
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/createUser`, payload);
      if (response.status === 200) {
        dispatch(signupStore({}));
        toast.success('Signup successful!');
      } else {
        setApiError(`Signup failed with status: ${response.status}`);
        clearErrors();
      }
    } catch (error) {
      setApiError('An error occurred during signup. Please try again.');
      clearErrors();
    }
  }

  // Function to clear errors after 2 seconds
  const clearErrors = () => {
    setTimeout(() => {
      setApiError('');
      setNameError('');
      setEmailError('');
      setPasswordError('');
      setConfirmPasswordError('');
      setTitleError('');
      setCompanyError('');
    }, 2000);
  };

  // Function to handle plan selection
  const selectPlans = () => {
    navigate('/pages/pricing');
  };

  return (
    <div className="register">
      <div className="brand">
        <ToastContainer />
      </div>
      <div className="register-content">
        <form onSubmit={handleSubmit}>
          <h1 className="text-center">Sign Up</h1>
          <p className="text-inverse text-opacity-50 text-center">One Admin ID is all you need to access all the Admin services.</p>

          {/* Display API error at the top */}
          {apiError && <div className="alert alert-danger">{apiError}</div>}

          <div className="mb-3">
            <label className="form-label">Name <span className="text-danger">*</span></label>
            <input
              type="text"
              className="form-control form-control-lg bg-white bg-opacity-5"
              placeholder="e.g John Smith"
              value={name}
              onChange={(e) => { setName(e.target.value); setNameError(''); }}
            />
            {nameError && <div className="text-danger">{nameError}</div>}
          </div>
          <div className="mb-3">
            <label className="form-label">Email Address <span className="text-danger">*</span></label>
            <input
              type="text"
              className="form-control form-control-lg bg-white bg-opacity-5"
              placeholder="username@address.com"
              value={email}
              onChange={(e) => { setEmail(e.target.value); setEmailError(''); }}
            />
            {emailError && <div className="text-danger">{emailError}</div>}
          </div>
          <div className="mb-3">
            <label className="form-label">Password</label>
            <div className="input-group">
              <input
                type={showPassword ? 'text' : 'password'}
                className="form-control form-control-lg bg-white bg-opacity-5"
                value={password}
                onChange={(e) => { setPassword(e.target.value); setPasswordError(''); }}
              />
              <span className="input-group-text" onClick={() => setShowPassword(!showPassword)}>
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </span>
            </div>
            {passwordError && <div className="text-danger">{passwordError}</div>}
          </div>

          <div className="mb-3">
            <label className="form-label">Confirm Password</label>
            <div className="input-group">
              <input
                type={showConfirmPassword ? 'text' : 'password'}
                className="form-control form-control-lg bg-white bg-opacity-5"
                value={confirmPassword}
                onChange={(e) => { setConfirmPassword(e.target.value); }}
              />
              <span className="input-group-text" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
              </span>
            </div>
            {confirmPasswordError && <div className="text-danger">{confirmPasswordError}</div>}
          </div>
          <div className="mb-3">
            <label className="form-label">Title <span className="text-danger">*</span></label>
            <input
              type="text"
              className="form-control form-control-lg bg-white bg-opacity-5"
              value={title}
              onChange={(e) => { setTitle(e.target.value); setTitleError(''); }}
            />
            {titleError && <div className="text-danger">{titleError}</div>}
          </div>
          <div className="mb-3">
            <label className="form-label">Company / Institution <span className="text-danger">*</span></label>
            <input
              type="text"
              className="form-control form-control-lg bg-white bg-opacity-5"
              value={company}
              onChange={(e) => { setCompany(e.target.value); setCompanyError(''); }}
            />
            {companyError && <div className="text-danger">{companyError}</div>}
          </div>
          <div className="mb-3">
            <label className="form-label">Country <span className="text-danger">*</span></label>
            <CountryDropdown
              value={country}
              onChange={(val) => setCountry(val)}
              className="form-select form-select-lg bg-white bg-opacity-5"
            />
          </div>
          <div className="mb-3">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="customCheck1" />
              <label className="form-check-label" htmlFor="customCheck1">
                I have read and agree to the <a href="#/">Terms of Use</a> and <a href="#/">Privacy Policy</a>.
              </label>
            </div>
            <button
              type="button"
              className="btn btn-outline-theme btn-lg d-block w-100"
              style={{ cursor: "pointer", textDecoration: "none" }}
              onClick={selectPlans}
            >
              Select Plans
            </button>
          </div>
          <div className="mb-3">
            <button type="submit" className="btn btn-outline-theme btn-lg d-block w-100">Sign Up</button>
          </div>
          <div className="text-inverse text-opacity-50 text-center">
            Already have an Admin ID? <Link to="/pages/login" style={{ textDecoration: 'none' }}>Sign In</Link>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PagesRegister;
