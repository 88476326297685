import React, { useState, useEffect } from 'react';
import { CountryDropdown } from 'react-country-region-selector';
import 'lity/dist/lity.min.css';
import axios from 'axios';  // Assuming you're using axios for API calls
import { toast, ToastContainer } from 'react-toastify';  // Import toast
import 'react-toastify/dist/ReactToastify.css';  // Import toast styles
import { getUserSuccess, getUserFailure } from '../../redux/slices/getUser';
import { useSelector, useDispatch } from 'react-redux';

function Profile() {
  const userInfo = useSelector((state) => state.getUser.user); // Fetch userInfo from Redux
  const token = sessionStorage.getItem('token');

  const dispatch = useDispatch();

  // Form state to manage input values
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    title: '',
    organisation: '',
    country: '',
    userType: '',
    subscriptionType: ''
  });

  // Initialize the form with the userInfo values when the component mounts
  useEffect(() => {
    if (userInfo) {
      setFormData({
        name: userInfo.username || '',
        email: userInfo.email || '',
        title: userInfo.title || '',
        organisation: userInfo.organisation || '',
        country: userInfo.country || '',
        userType: userInfo.Role?.name === 'superAdmin' 
          ? 'Super Admin' 
          : userInfo.Role?.name === 'subAdmin' 
          ? 'Sub Admin' 
          : userInfo.Role?.name || '', 
        subscriptionType: userInfo.Subscription?.name || '' 
      });
    }
  }, [userInfo]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const payload = {
      username: formData.name,
      email: formData.email,
      title: formData.title,
      organisation: formData.organisation,
      country: formData.country,
    };

    try {
      // Check user role to determine which API to call
      let response;
      if (userInfo.Role?.name === 'superAdmin' || userInfo.Role?.name === 'subAdmin') {
        // Call admin API
        response = await axios.put(`${process.env.REACT_APP_BASE_URL}/editAdminProfile/${userInfo.id}`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,  // Add your admin token for authentication
          }
        });

        var adminUser = await axios.get(`${process.env.REACT_APP_BASE_URL}/getAdminUser`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        dispatch(getUserSuccess(adminUser.data));


      } else {
        // Call user API
        response = await axios.put(`${process.env.REACT_APP_BASE_URL}/editUserProfile/${userInfo.id}`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,  // Add your user token for authentication
          }
        });

        var normalUser = await axios.get(`${process.env.REACT_APP_BASE_URL}/getUser`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        dispatch(getUserSuccess(normalUser.data));
      }

      toast.success('Profile updated successfully'); // Show success toast
      console.log('Response:', response.data);

    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error('An error occurred while updating the profile'); // Show error toast
    }
  };

  return (
    <div className="profile">
      <div className="profile-content">
        <h1 className="text-center">Profile</h1>
        <p className="text-inverse text-opacity-50 text-center">
          Below are the details of your profile.
        </p>

        <form onSubmit={handleSubmit}>
          {/* Name Field */}
          <div className="mb-3">
            <label className="form-label">Name</label>
            <input
              type="text"
              className="form-control form-control-lg bg-white bg-opacity-5"
              name="name"
              value={formData.name} // Set value from state
              onChange={handleInputChange} // Handle changes
            />
          </div>

          {/* Email Address Field */}
          <div className="mb-3">
            <label className="form-label">Email Address</label>
            <input
              type="text"
              className="form-control form-control-lg bg-white bg-opacity-5"
              name="email"
              value={formData.email} // Set value from state
              onChange={handleInputChange} // Handle changes
            />
          </div>

          {/* Title Field */}
          <div className="mb-3">
            <label className="form-label">Title</label>
            <input
              type="text"
              className="form-control form-control-lg bg-white bg-opacity-5"
              name="title"
              value={formData.title} // Set value from state
              onChange={handleInputChange} // Handle changes
            />
          </div>

          {/* Organisation Field */}
          <div className="mb-3">
            <label className="form-label">Company / Institution</label>
            <input
              type="text"
              className="form-control form-control-lg bg-white bg-opacity-5"
              name="organisation"
              value={formData.organisation} // Set value from state
              onChange={handleInputChange} // Handle changes
            />
          </div>

          {/* Country Field */}
          <div className="mb-3">
            <label className="form-label">Country</label>
            <CountryDropdown
              value={formData.country} // Set value from state
              onChange={(val) => setFormData({ ...formData, country: val })} // Handle country selection
              className="form-select form-select-lg bg-white bg-opacity-5"
            />
          </div>

          {/* User Type Field (disabled) */}
          <div className="mb-3">
            <label className="form-label">User Type</label>
            <input
              type="text"
              className="form-control form-control-lg bg-white bg-opacity-5"
              name="userType"
              value={formData.userType} // Set value from state
              disabled // Disable the input
            />
          </div>

          {/* Subscription Type Field (disabled) */}
          {userInfo?.Role?.name !== "superAdmin" ? (
            <div className="mb-3">
              <label className="form-label">Subscription Type</label>
              <input
                type="text"
                className="form-control form-control-lg bg-white bg-opacity-5"
                name="subscriptionType"
                value={formData.subscriptionType} // Set value from state
                disabled // Disable the input
              />
            </div>
          ) : null}

          {/* Submit button */}
          <div className="mb-3">
            <button type="submit" className="btn btn-outline-theme btn-lg d-block w-100">
              Submit
            </button>
          </div>
        </form>
      </div>

      {/* Toast container for showing notifications */}
      <ToastContainer />
    </div>
  );
}

export default Profile;
