import React, { useState, useEffect, useRef } from "react";

import "react-toastify/dist/ReactToastify.css";

import UserDataDesign from "./../../components/userData/iframebox";




function PlaybookUseCases() {
// "cc" 
  return (

    <UserDataDesign
    tabType={"embedded"}

  />
  );
}

export default PlaybookUseCases;
