import React, { useState } from "react";
import { Card } from "./../../components/card/card.jsx";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import Sidebar from "../../components/sidebar/sidebar.jsx";
// import SidebarNav from "../../components/sidebar/sidebar-nav.jsx";

function PagesOrders() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [editshows, setEditShows] = useState(false);
  const handleCloses = () => setEditShows(false);
  const handleShows = () => setEditShows(true);

  const [addShow, setAddShow] = useState(false);
  const handleAddClose = () => setAddShow(false);
  const handleAddShow = () => setAddShow(true);

  const [deleteShow, setDeleteShow] = useState(false);
  const handleDeleteClose = () => setDeleteShow(false);
  const handleDeleteShow = () => setDeleteShow(true);

  return (
    <div>
      <Sidebar />
      {/* <SidebarNav /> */}
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#/">PAGES</a>
            </li>
            <li className="breadcrumb-item active">SUBSCRIPTION</li>
          </ul>
          <h1 className="page-header mb-0">Subscription</h1>
        </div>

        <div className="ms-auto">
          <a
            href="#/"
            className="btn btn-outline-theme"
            onClick={handleAddShow}
          >
            <i className="fa fa-plus-circle fa-fw me-1"></i> ADD
          </a>
        </div>
      </div>

      <div className="mb-md-4 mb-3 d-md-flex">
        <div className="mt-md-0 mt-2">
          <a
            href="#/"
            className="text-inverse text-opacity-75 text-decoration-none"
          >
            <i className="fa fa-download fa-fw me-1 text-theme"></i> Export
          </a>
        </div>
        <div className="ms-md-4 mt-md-0 mt-2 dropdown-toggle">
          <a
            href="#/"
            data-bs-toggle="dropdown"
            className="text-inverse text-opacity-75 text-decoration-none"
          >
            More Actions
          </a>
          <div className="dropdown-menu">
            <a className="dropdown-item" href="#/">
              Action
            </a>
            <a className="dropdown-item" href="#/">
              Another action
            </a>
            <a className="dropdown-item" href="#/">
              Something else here
            </a>
            <div role="separator" className="dropdown-divider"></div>
            <a className="dropdown-item" href="#/">
              Separated link
            </a>
          </div>
        </div>
      </div>

      <Card>
        <div className="tab-content p-4">
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="border-top-0 pt-0 pb-2">Name</th>
                  <th className="border-top-0 pt-0 pb-2">Price</th>
                  <th className="border-top-0 pt-0 pb-2">Description</th>
                  <th className="border-top-0 pt-0 pb-2">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="align-middle">Lorem</td>
                  <td className="py-1 align-middle">
                    <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                      $ 396
                    </span>
                  </td>
                  <td className="align-middle">Lorem Ipsum but with a bit</td>
                  <td>
                    <div className="action-btn-group d-flex">
                      <button
                        className="btn btn-edit border border-warning text-warning px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center"
                        onClick={handleShows}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-show border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center mx-2"
                        onClick={handleShow}
                      >
                        Show
                      </button>
                      <button
                        className="btn btn-delete border border-danger text-danger px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center"
                        onClick={handleDeleteShow}
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="d-md-flex align-items-center">
            <div className="me-md-auto text-md-left text-center mb-2 mb-md-0">
              Showing 1 to 10 of 57 entries
            </div>
            <ul className="pagination mb-0 justify-content-center">
              <li className="page-item disabled">
                <a className="page-link" href="#/">
                  Previous
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#/">
                  1
                </a>
              </li>
              <li className="page-item active">
                <a className="page-link" href="#/">
                  2
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#/">
                  3
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#/">
                  4
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#/">
                  5
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#/">
                  6
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#/">
                  Next
                </a>
              </li>
            </ul>
          </div>
        </div>
      </Card>

      <Modal show={show} onHide={handleClose} className="right-side-modal">
        <Modal.Header closeButton>
          <Modal.Title>Show Subscription</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="form-group-area">
            <div className="form-group">
              <lable>Name</lable>
              <input className="form-control" disabled placeholder="Lorem" />
            </div>
            <div className="form-group">
              <lable>Price</lable>
              <input className="form-control" disabled placeholder="$396" />
            </div>
            <div className="form-group">
              <lable>Description</lable>
              <textarea
                className="form-control"
                disabled
                placeholder="Lorem Ipsum but with a bit"
              ></textarea>
            </div>
            <div className="form-button">
              <button className="btn btn-outline-theme">Save</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={editshows}
        onHide={handleCloses}
        className="right-side-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Subscription</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group-area">
            <div className="form-group">
              <lable>Name</lable>
              <input className="form-control" />
            </div>
            <div className="form-group">
              <lable>Price</lable>
              <input className="form-control" />
            </div>
            <div className="form-group">
              <lable>Description</lable>
              <textarea className="form-control"></textarea>
            </div>
            <div className="form-button">
              <button className="btn btn-outline-theme">Save Changes</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={addShow}
        onHide={handleAddClose}
        className="right-side-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Subscription</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group-area">
            <div className="form-group">
              <lable>Name</lable>
              <input className="form-control" />
            </div>
            <div className="form-group">
              <lable>Price</lable>
              <input className="form-control" />
            </div>
            <div className="form-group">
              <lable>Description</lable>
              <textarea className="form-control"></textarea>
            </div>
            <div className="form-button">
              <button className="btn btn-outline-theme ">ADD</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={deleteShow}
        onHide={handleDeleteClose}
        className="delete-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Subscription</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this subscription?</p>

          <div className="delete-btn-group">
            <button
              className="btn dlt-btn dlt-btn-close"
              onClick={handleDeleteClose}
            >
              Cancel
            </button>
            <button className="btn dlt-btn">Delete</button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default PagesOrders;
